import { useState, useEffect, useReducer } from "react";
import axios from "axios";

// Styles
import "./assets/styles/style.scss";

const listItems = [
  "Kat Stratford (10 Things I Hate About You)",
  "Donkey (Shrek)",
  "Marge Gunderson (Fargo)",
  "Ellen Tigh (Battlestar Galactica)",
  "Dr. Alan Grant (Jurassic Park)",
  "Oskar Schindler (Schindler's List)",
  "Jack Skellington (The Nightmare Before Christmas)",
  "John Coffey (The Green Mile)",
  "Captain John Miller (Saving Private Ryan)",
  "Clarice Starling (The Silence of the Lambs)",
  "Don Lockwood (Singin' in the Rain)",
  "Rose DeWitt Bukater (Titanic)",
  "Ethan Hunt (Mission: Impossible series)",
  "Anton Chigurh (No Country for Old Men)",
  "Edward Scissorhands (Edward Scissorhands)",
  "Dominic Toretto (Fast & Furious series)",
  "Maximus (Gladiator)",
  "Frank Abagnale Jr. (Catch Me If You Can)",
  "Marv (Sin City)",
  "Aragorn (The Lord of the Rings)",
  "Jules Winnfield (Pulp Fiction)",
  "William Wallace (Braveheart)",
  "Mavis Gary (Young Adult)",
  "Jareth the Goblin King (Labyrinth)",
  "Annie Hall (Annie Hall)",
  "Paul Edgecomb (The Green Mile)",
  "Alex DeLarge (A Clockwork Orange)",
  "George Bailey (It's a Wonderful Life)",
  "Danny Ocean (Ocean's Eleven)",
  "Captain America (Captain America series)",
  "Amy Dunne (Gone Girl)",
  "Danny Zuko (Grease)",
  "Ethan Edwards (The Searchers)",
  "R.P. McMurphy (One Flew Over the Cuckoo's Nest)",
  "James Bond (Casino Royale)",
  "Darth Vader (Star Wars)",
  "Hermione Granger (Harry Potter series)",
  "Tony Stark (Iron Man) (Iron Man)",
  "Elle Woods (Legally Blonde)",
  "The Joker (The Dark Knight)",
  "Forrest Gump (Forrest Gump)",
  "Hannibal Lecter (The Silence of the Lambs)",
  "Jack Sparrow (Pirates of the Caribbean)",
  "Lara Croft (Tomb Raider)",
  "Tyler Durden (Fight Club)",
  "Katniss Everdeen (The Hunger Games)",
  "John Wick (John Wick)",
  "Captain Jack Harkness (Doctor Who, Torchwood)",
  "Trinity (The Matrix)",
  "Ellen Ripley (Alien)",
  "Vito Corleone (The Godfather)",
  "Atticus Finch (To Kill a Mockingbird)",
  "Maximus Decimus Meridius (Gladiator)",
  "Amélie Poulain (Amélie)",
  "Harry Potter (Harry Potter series)",
  "Joker (Joker)",
  "Neo (The Matrix)",
  "Michael Corleone (The Godfather)",
  "Indiana Jones (Indiana Jones series)",
  "Wolverine (X-Men series)",
  "Andy Dufresne (The Shawshank Redemption)",
  "Don Vito Corleone (The Godfather)",
  "Gollum/Smeagol (The Lord of the Rings)",
  "Tony Montana (Scarface)",
  "Mary Poppins (Mary Poppins)",
  "Jack Torrance (The Shining)",
  "Deadpool (Deadpool)",
  "Captain Jack Sparrow (Pirates of the Caribbean)",
  "Sarah Connor (The Terminator series)",
  "Donnie Darko (Donnie Darko)",
  "Luke Skywalker (Star Wars)",
  "Iron Man (The Avengers)",
  "Severus Snape (Harry Potter series)",
  "Marlin (Finding Nemo)",
  "T-800 (The Terminator) (The Terminator series)",
  "Matilda Wormwood (Matilda)",
  "Lisbeth Salander (The Girl with the Dragon Tattoo)",
  "Mia Wallace (Pulp Fiction)",
  "Princess Leia Organa (Star Wars)",
  "Alice (Alice in Wonderland)",
  "Evelyn Salt (Salt)",
  "Catherine Tramell (Basic Instinct)",
  "Imperator Furiosa (Mad Max: Fury Road)",
  "Éowyn (The Lord of the Rings)",
  "Bridget Jones (Bridget Jones's Diary)",
  "Wednesday Addams (The Addams Family)",
  "Neytiri (Avatar)",
  "Elle Woods (Legally Blonde)",
  "Beca Mitchell (Pitch Perfect)",
  "Dobby (Harry Potter)",
  "Sirius Black (Harry Potter)",
  "Albus Percival Wulfric Brian Dumbledore (Harry Potter)",
  "Captain America (Marvel Cinematic Universe)",
  "Kevin McCallister (Home Alone)",
  "Marv Murchins (Home Alone)",
  "Harry Lyme (Home Alone)",
  "Thomas (Maze Runner)",
  "Newt (Maze Runner)",
  "J. Daniel Atlas (Now you see me)",
  "Smolder Bravestone (Jumanji: Welcome to the Jungle)",
];

function Admin({ baseDomain }) {
  const [data, setData] = useState([]);
  const [countCharacters, setCountCharacters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("token");

  async function getData() {
    setIsLoading(true);

    try {
      const response = await axios.get(`${baseDomain}/getResults`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //console.log(response.data);
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      window.location.href = "/login";
      console.log(error);
    }
  }

  async function getCountCharacters() {
    setIsLoading(true);

    try {
      const response = await axios.get(`${baseDomain}/getCountCharacters`);
      console.log(response.data);
      setCountCharacters(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const ParenthesesMatch = ({ title }) => {
    const matchResult = title.match(/\((.*?)\)/);

    if (matchResult) {
      const matchedText = matchResult[1];
      return matchedText;
    }
  };

  useEffect(() => {
    getData();
    getCountCharacters();
  }, []);

  return (
    <div className="App p-5 admin">
      <div className="text-center text-white">
        {isLoading ? (
          <h1>LOADING ...</h1>
        ) : (
          <>
            <div className="section-stats my-2" id="top5">
              <div className="container narrower px-4">
                <div className="row">
                  <div className="col-md-12 items">
                    {countCharacters.slice(0, 100).map((item) => {
                      if (item !== null) {
                        return (
                          <div
                            key={item.index}
                            className="d-flex justify-content-between align-items-start mb-4 text-start">
                            <div>
                              <div className="d-flex align-items-start">
                                <div>
                                  <h2 className="mb-0 me-3">{item.index}</h2>
                                </div>
                                <div>
                                  <h2 className="title mb-1">
                                    {listItems[
                                      parseInt(item.title) - 1
                                    ].replace(/\s*\([^)]*\)\s*/, "")}
                                    <small>
                                      <ParenthesesMatch
                                        title={
                                          listItems[parseInt(item.title) - 1]
                                        }
                                      />
                                    </small>
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div>
                              <h1 className="fw-bold">{item.count}</h1>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
            <table className="table text-start text-white">
              <thead>
                <tr>
                  <th style={{ width: "5%" }} scope="col">
                    #
                  </th>
                  <th style={{ width: "40%" }} scope="col">
                    Prompt
                  </th>
                  <th style={{ width: "50%" }} scope="col">
                    Result
                  </th>
                  <th style={{ width: "5%" }} scope="col">
                    Rating
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  .sort((a, b) => b.created.localeCompare(a.created))
                  .map((item, index) => (
                    <tr key={index}>
                      <th scope="row">{data.length - index}</th>
                      <td>
                        <small className="d-none">{item.prompt}</small>
                        <p className="fw-bold">VARIABLE</p>
                        <ul>
                          {item.answers &&
                            item.answers.map((item) => {
                              if (typeof item === "object" && item !== null) {
                                Object.values(item).map((value, index) => (
                                  <li key={`item-${index}`}>{value}</li>
                                ));
                              } else {
                                return <li>{item}</li>;
                              }
                            })}
                        </ul>
                        <br />
                        Published:
                        <br />
                        <strong>{convertUtcToSlovenia(item.created)}</strong>
                        <br />
                        {item.proccessingTime && (
                          <>
                            <br />
                            Proccessing time:
                            <br />
                            <strong>
                              {Math.trunc(item.proccessingTime / 1000)}s
                            </strong>
                            <br />
                          </>
                        )}
                      </td>
                      <td>
                        {item.result !== null && (
                          <>
                            {item.result.characterIndex !== undefined &&
                              item.result.characterIndex < 100 && (
                                <h1>
                                  {listItems[
                                    parseInt(item.result.characterIndex) - 1
                                  ].replace(/\s*\([^)]*\)\s*/, "")}{" "}
                                  from{" "}
                                  <ParenthesesMatch
                                    title={
                                      listItems[
                                        parseInt(item.result.characterIndex) - 1
                                      ]
                                    }
                                  />
                                </h1>
                              )}

                            <div
                              className="result-generated"
                              dangerouslySetInnerHTML={{
                                __html: item.result.result,
                              }}></div>
                          </>
                        )}
                      </td>
                      <td>
                        {item.rating !== null && item.rating.length !== 0 ? (
                          <>
                            <strong>
                              {Math.round(
                                item.rating.reduce((a, b) => a + b, 0) /
                                  item.rating.length
                              )}
                            </strong>
                            <small> ({item.rating.length}x)</small>
                          </>
                        ) : (
                          <strong>N/A </strong>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}

function convertUtcToSlovenia(utcTimestamp) {
  const createdUtc = new Date(utcTimestamp);
  const createdSlovenia = new Date(createdUtc.getTime() + 0 * 60 * 60 * 1000);
  const createdDateString = createdSlovenia.toLocaleDateString("sl-SI", {
    timeZone: "Europe/Ljubljana",
  });
  const createdTimeString = createdSlovenia.toLocaleTimeString("sl-SI", {
    timeZone: "Europe/Ljubljana",
  });
  return `${createdDateString} ${createdTimeString}`;
}

export default Admin;
