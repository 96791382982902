export const getDataQuestions = (i18n) => ({
  quizId: 1,
  content: [
    {
      type: "text",
      questionText: i18n.t("Form1H"),
      questionDescription: i18n.t("Form1H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form1_01"),
          path: 2,
          productTags: i18n.t("Form1_01P"),
        },
        {
          answerText: i18n.t("Form1_02"),
          path: 2,
          productTags: i18n.t("Form1_02P"),
        },
        {
          answerText: i18n.t("Form1_03"),
          path: 2,
          productTags: i18n.t("Form1_03P"),
        },
        {
          answerText: i18n.t("Form1_04"),
          path: 2,
          productTags: i18n.t("Form1_04P"),
        },
      ],
      id: 1,
    },
    {
      type: "textimg",
      questionText: i18n.t("Form2H"),
      questionDescription: i18n.t("Form2H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q2a1.jpg",
          answerText: i18n.t("Form2_01"),
          path: 3,
          productTags: i18n.t("Form2_01P"),
        },
        {
          answerImg: "q2a2.jpg",
          answerText: i18n.t("Form2_02"),
          path: 3,
          productTags: i18n.t("Form2_02P"),
        },
        {
          answerImg: "q2a3.jpg",
          answerText: i18n.t("Form2_03"),
          path: 3,
          productTags: i18n.t("Form2_03P"),
        },
        {
          answerImg: "q2a4.jpg",
          answerText: i18n.t("Form2_04"),
          path: 3,
          productTags: i18n.t("Form2_04P"),
        },
      ],
      id: 2,
    },
    {
      type: "textimg",
      questionText: i18n.t("Form3H"),
      questionDescription: i18n.t("Form3H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q3a1.jpg",
          answerText: i18n.t("Form3_01"),
          path: 4,
          productTags: i18n.t("Form3_01P"),
        },
        {
          answerImg: "q3a2.jpg",
          answerText: i18n.t("Form3_02"),
          path: 4,
          productTags: i18n.t("Form3_02P"),
        },
        {
          answerImg: "q3a3.jpg",
          answerText: i18n.t("Form3_03"),
          path: 4,
          productTags: i18n.t("Form3_03P"),
        },
        {
          answerImg: "q3a4.jpg",
          answerText: i18n.t("Form3_04"),
          path: 4,
          productTags: i18n.t("Form3_04P"),
        },
      ],
      id: 3,
    },
    {
      type: "text",
      questionText: i18n.t("Form4H"),
      questionDescription: i18n.t("Form4H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form4_01"),
          path: 5,
          productTags: i18n.t("Form4_01P"),
        },
        {
          answerText: i18n.t("Form4_02"),
          path: 5,
          productTags: i18n.t("Form4_02P"),
        },
        {
          answerText: i18n.t("Form4_03"),
          path: 5,
          productTags: i18n.t("Form4_03P"),
        },
        {
          answerText: i18n.t("Form4_04"),
          path: 5,
          productTags: i18n.t("Form4_04P"),
        },
      ],
      id: 4,
    },
    {
      type: "textimg",
      questionText: i18n.t("Form5H"),
      questionDescription: i18n.t("Form5H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q5a1.jpg",
          answerText: i18n.t("Form5_01"),
          path: 6,
          productTags: i18n.t("Form5_01P"),
        },
        {
          answerImg: "q5a2.jpg",
          answerText: i18n.t("Form5_02"),
          path: 6,
          productTags: i18n.t("Form5_02P"),
        },
        {
          answerImg: "q5a3.jpg",
          answerText: i18n.t("Form5_03"),
          path: 6,
          productTags: i18n.t("Form5_03P"),
        },
        {
          answerImg: "q5a4.jpg",
          answerText: i18n.t("Form5_04"),
          path: 6,
          productTags: i18n.t("Form5_04P"),
        },
      ],
      id: 5,
    },
    {
      type: "textimg",
      questionText: i18n.t("Form6H"),
      questionDescription: i18n.t("Form6H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q6a1.jpg",
          answerText: i18n.t("Form6_01"),
          path: 7,
          productTags: i18n.t("Form6_01P"),
        },
        {
          answerImg: "q6a2.jpg",
          answerText: i18n.t("Form6_02"),
          path: 7,
          productTags: i18n.t("Form6_02P"),
        },
        {
          answerImg: "q6a3.jpg",
          answerText: i18n.t("Form6_03"),
          path: 7,
          productTags: i18n.t("Form6_03P"),
        },
        {
          answerImg: "q6a4.jpg",
          answerText: i18n.t("Form6_04"),
          path: 7,
          productTags: i18n.t("Form6_04P"),
        },
      ],
      id: 6,
    },
    {
      type: "text",
      questionText: i18n.t("Form7H"),
      questionDescription: i18n.t("Form7H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form7_01"),
          path: 8,
          productTags: i18n.t("Form7_01P"),
        },
        {
          answerText: i18n.t("Form7_02"),
          path: 8,
          productTags: i18n.t("Form7_02P"),
        },
        {
          answerText: i18n.t("Form7_03"),
          path: 8,
          productTags: i18n.t("Form7_03P"),
        },
        {
          answerText: i18n.t("Form7_04"),
          path: 8,
          productTags: i18n.t("Form7_04P"),
        },
      ],
      id: 7,
    },
    {
      type: "text",
      questionText: i18n.t("Form8H"),
      questionDescription: i18n.t("Form8H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form8_01"),
          path: 9,
          productTags: i18n.t("Form8_01P"),
        },
        {
          answerText: i18n.t("Form8_02"),
          path: 9,
          productTags: i18n.t("Form8_02P"),
        },
        {
          answerText: i18n.t("Form8_03"),
          path: 9,
          productTags: i18n.t("Form8_03P"),
        },
        {
          answerText: i18n.t("Form8_04"),
          path: 9,
          productTags: i18n.t("Form8_04P"),
        },
      ],
      id: 8,
    },
    {
      type: "textimg",
      questionText: i18n.t("Form9H"),
      questionDescription: i18n.t("Form9H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q9a1.jpg",
          answerText: i18n.t("Form9_01"),
          path: 10,
          productTags: i18n.t("Form9_01P"),
        },
        {
          answerImg: "q9a2.jpg",
          answerText: i18n.t("Form9_02"),
          path: 10,
          productTags: i18n.t("Form9_02P"),
        },
        {
          answerImg: "q9a3.jpg",
          answerText: i18n.t("Form9_03"),
          path: 10,
          productTags: i18n.t("Form9_03P"),
        },
        {
          answerImg: "q9a4.jpg",
          answerText: i18n.t("Form9_04"),
          path: 10,
          productTags: i18n.t("Form9_04P"),
        },
      ],
      id: 9,
    },

    {
      type: "textimg",
      questionText: i18n.t("Form10H"),
      questionDescription: i18n.t("Form10H"),
      questionInfo: null,
      answerOptions: [
        {
          answerImg: "q10a1.jpg",
          answerText: i18n.t("Form10_01"),
          path: 11,
          productTags: i18n.t("Form10_01P"),
        },
        {
          answerImg: "q10a2.jpg",
          answerText: i18n.t("Form10_02"),
          path: 11,
          productTags: i18n.t("Form10_02P"),
        },
        {
          answerImg: "q10a3.jpg",
          answerText: i18n.t("Form10_03"),
          path: 11,
          productTags: i18n.t("Form10_03P"),
        },
        {
          answerImg: "q10a4.jpg",
          answerText: i18n.t("Form10_04"),
          path: 11,
          productTags: i18n.t("Form10_04P"),
        },
      ],
      id: 10,
    },

    {
      type: "text",
      questionText: i18n.t("Form11H"),
      questionDescription: i18n.t("Form11H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form11_01"),
          path: 12,
          productTags: i18n.t("Form11_01P"),
        },
        {
          answerText: i18n.t("Form11_02"),
          path: 12,
          productTags: i18n.t("Form11_02P"),
        },
        {
          answerText: i18n.t("Form11_03"),
          path: 12,
          productTags: i18n.t("Form11_03P"),
        },
        {
          answerText: i18n.t("Form11_04"),
          path: 12,
          productTags: i18n.t("Form11_04P"),
        },
      ],
      id: 11,
    },

    {
      type: "text",
      questionText: i18n.t("Form12H"),
      questionDescription: i18n.t("Form12H"),
      questionInfo: null,
      answerOptions: [
        {
          answerText: i18n.t("Form12_01"),
          path: "result",
          productTags: i18n.t("Form12_01P"),
        },
        {
          answerText: i18n.t("Form12_02"),
          path: "result",
          productTags: i18n.t("Form12_02P"),
        },
        {
          answerText: i18n.t("Form12_03"),
          path: "result",
          productTags: i18n.t("Form12_03P"),
        },
        {
          answerText: i18n.t("Form12_04"),
          path: "result",
          productTags: i18n.t("Form12_04P"),
        },
      ],
      id: 12,
    },
  ],
});
