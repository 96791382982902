import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useParams, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { RWebShare } from "react-web-share";
import { trackEvent } from "views/tracking";

import { importAll } from "utils/importImages";

import Hero from "views/Common/Hero";
import StoryActions from "views/Common/StoryActions";
import Top5 from "views/Common/Top5";

// Styles
import "./assets/styles/style.scss";

const listItems = [
  "Kat Stratford (10 Things I Hate About You)",
  "Donkey (Shrek)",
  "Marge Gunderson (Fargo)",
  "Ellen Tigh (Battlestar Galactica)",
  "Dr. Alan Grant (Jurassic Park)",
  "Oskar Schindler (Schindler's List)",
  "Jack Skellington (The Nightmare Before Christmas)",
  "John Coffey (The Green Mile)",
  "Captain John Miller (Saving Private Ryan)",
  "Clarice Starling (The Silence of the Lambs)",
  "Don Lockwood (Singin' in the Rain)",
  "Rose DeWitt Bukater (Titanic)",
  "Ethan Hunt (Mission: Impossible series)",
  "Anton Chigurh (No Country for Old Men)",
  "Edward Scissorhands (Edward Scissorhands)",
  "Dominic Toretto (Fast & Furious series)",
  "Maximus (Gladiator)",
  "Frank Abagnale Jr. (Catch Me If You Can)",
  "Marv (Sin City)",
  "Aragorn (The Lord of the Rings)",
  "Jules Winnfield (Pulp Fiction)",
  "William Wallace (Braveheart)",
  "Mavis Gary (Young Adult)",
  "Jareth the Goblin King (Labyrinth)",
  "Annie Hall (Annie Hall)",
  "Paul Edgecomb (The Green Mile)",
  "Alex DeLarge (A Clockwork Orange)",
  "George Bailey (It's a Wonderful Life)",
  "Danny Ocean (Ocean's Eleven)",
  "Captain America (Captain America series)",
  "Amy Dunne (Gone Girl)",
  "Danny Zuko (Grease)",
  "Ethan Edwards (The Searchers)",
  "R.P. McMurphy (One Flew Over the Cuckoo's Nest)",
  "James Bond (Casino Royale)",
  "Darth Vader (Star Wars)",
  "Hermione Granger (Harry Potter series)",
  "Tony Stark (Iron Man) (Iron Man)",
  "Elle Woods (Legally Blonde)",
  "The Joker (The Dark Knight)",
  "Forrest Gump (Forrest Gump)",
  "Hannibal Lecter (The Silence of the Lambs)",
  "Jack Sparrow (Pirates of the Caribbean)",
  "Lara Croft (Tomb Raider)",
  "Tyler Durden (Fight Club)",
  "Katniss Everdeen (The Hunger Games)",
  "John Wick (John Wick)",
  "Captain Jack Harkness (Doctor Who, Torchwood)",
  "Trinity (The Matrix)",
  "Ellen Ripley (Alien)",
  "Vito Corleone (The Godfather)",
  "Atticus Finch (To Kill a Mockingbird)",
  "Maximus Decimus Meridius (Gladiator)",
  "Amélie Poulain (Amélie)",
  "Harry Potter (Harry Potter series)",
  "Joker (Joker)",
  "Neo (The Matrix)",
  "Michael Corleone (The Godfather)",
  "Indiana Jones (Indiana Jones series)",
  "Wolverine (X-Men series)",
  "Andy Dufresne (The Shawshank Redemption)",
  "Don Vito Corleone (The Godfather)",
  "Gollum/Smeagol (The Lord of the Rings)",
  "Tony Montana (Scarface)",
  "Mary Poppins (Mary Poppins)",
  "Jack Torrance (The Shining)",
  "Deadpool (Deadpool)",
  "Captain Jack Sparrow (Pirates of the Caribbean)",
  "Sarah Connor (The Terminator series)",
  "Donnie Darko (Donnie Darko)",
  "Luke Skywalker (Star Wars)",
  "Iron Man (The Avengers)",
  "Severus Snape (Harry Potter series)",
  "Marlin (Finding Nemo)",
  "T-800 (The Terminator) (The Terminator series)",
  "Matilda Wormwood (Matilda)",
  "Lisbeth Salander (The Girl with the Dragon Tattoo)",
  "Mia Wallace (Pulp Fiction)",
  "Princess Leia Organa (Star Wars)",
  "Alice (Alice in Wonderland)",
  "Evelyn Salt (Salt)",
  "Catherine Tramell (Basic Instinct)",
  "Imperator Furiosa (Mad Max: Fury Road)",
  "Éowyn (The Lord of the Rings)",
  "Bridget Jones (Bridget Jones's Diary)",
  "Wednesday Addams (The Addams Family)",
  "Neytiri (Avatar)",
  "Elle Woods (Legally Blonde)",
  "Beca Mitchell (Pitch Perfect)",
  "Dobby (Harry Potter)",
  "Sirius Black (Harry Potter)",
  "Albus Percival Wulfric Brian Dumbledore (Harry Potter)",
  "Captain America (Marvel Cinematic Universe)",
  "Kevin McCallister (Home Alone)",
  "Marv Murchins (Home Alone)",
  "Harry Lyme (Home Alone)",
  "Thomas (Maze Runner)",
  "Newt (Maze Runner)",
  "J. Daniel Atlas (Now you see me)",
  "Smolder Bravestone (Jumanji: Welcome to the Jungle)",
];

function Result({ baseDomain, socket, isVip }) {
  const [data, setData] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [optin, setOptin] = useState(false);
  const [isErrorOptin, setIsErrorOptin] = useState(false);
  const [isJobFailed, setIsJobFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [openVip, setOpenVip] = useState(false);

  const [partialResponse, setPartialResponse] = useState(null);

  let { userId } = useParams();

  const { t, i18n } = useTranslation();
  const recaptchaRef = useRef(null);

  const images = importAll(
    require.context("./assets/images/_sff", false, /\.(png|jpe?g|svg)$/)
  );

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 800px)",
  });

  const ParenthesesMatch = ({ title }) => {
    console.log(title);
    const matchResult = title.match(/\((.*?)\)/);

    if (matchResult) {
      const matchedText = matchResult[1];
      return matchedText;
    }
  };

  const sendEmail = async () => {
    if (optin) {
      setLoading(true);
      setIsErrorOptin(false);
      const captchaToken =
        localStorage.getItem("captcha") === "success"
          ? "success"
          : await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      axios
        .post(`${baseDomain}/sendEmail`, {
          tomail: email,
          result: data.result,
          firstname: data.firstname,
          author: data.author,
          id: userId,
          captchaToken,
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setLoading(false);
            setIsSent(true);
            setIsError(false);
            setEmail("");
            trackEvent("user_interaction", "completed", "signup");
          } else if (response.data.msg === "fail") {
            setIsError(true);
            setLoading(false);
            setIsSent(false);
            setEmail("");
          }
        });
    } else {
      setIsErrorOptin(true);
    }
  };

  const sendEmailVIP = async () => {
    if (name !== "" && surname !== "") {
      setLoading(true);
      setIsErrorOptin(false);
      const captchaToken =
        localStorage.getItem("captcha") === "success"
          ? "success"
          : await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      axios
        .post(`${baseDomain}/sendEmailVip`, {
          result: data.result,
          name,
          surname,
          id: userId,
          url: `${baseDomain}/result/${userId}`,
          captchaToken,
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setLoading(false);
            setIsSent(true);
            setIsError(false);
            setEmail("");
            //trackEvent("user_interaction", "completed", "signup");
          } else if (response.data.msg === "fail") {
            setIsError(true);
            setLoading(false);
            setIsSent(false);
            setEmail("");
          }
        });
    } else {
      setIsErrorOptin(true);
    }
  };

  const handleResetButton = () => {
    setIsJobFailed(false);
    trackEvent("user_interaction", "click", "try_again");
  };

  useEffect(() => {
    const getResponse = async () => {
      try {
        const res = await axios.get(`${baseDomain}/getResult/${userId}`);

        setData(res.data);
        console.log(res.data.result.pickedCharacter);
        trackEvent(
          "user_interaction",
          "completed",
          "character",
          res.data.result.pickedCharacter
        );
      } catch (err) {
        console.log("error", err);
      }
    };

    socket.on("job failed", (data) => {
      if (userId === data.jobId) {
        setTimeout(() => {
          setIsJobFailed(true);
        }, 100);
      }
    });

    socket.on("job progress", (data) => {
      if (userId === data.jobId) {
        setPartialResponse(data.partial.text);
      }
    });

    socket.on("job completed", (data) => {
      if (userId === data.jobId) {
        setTimeout(() => {
          getResponse();
        }, 100);
      }
    });

    getResponse();
  }, []);

  return (
    <Hero t={t} layout="result">
      <AnimatePresence>
        {isJobFailed && (
          <div className="splash px-4">
            <h2 style={{ letterSpacing: 0 }}>{t("LoaderP1")}</h2>

            <h3 className="fw-normal mt-3" style={{ letterSpacing: 0 }}>
              {t("LoaderP2")}
            </h3>

            <button
              onClick={() => handleResetButton()}
              className="btn gradient-orange  text-white fw-bold d-flex align-items-center justify-content-center text-center text-uppercase mx-auto mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="me-2">
                <path
                  d="M13.3333 8H2.66667M2.66667 8L6.66667 12M2.66667 8L6.66667 4"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {t("TryAgain")}
            </button>
          </div>
        )}

        {openVip && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 0.5,
            }}
            className="popup d-flex align-items-center justify-content-center">
            <div className="popup-inner">
              <svg
                className="close"
                onClick={() => setOpenVip(false)}
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none">
                <rect
                  x="16.2632"
                  y="4.94971"
                  width="2"
                  height="16"
                  transform="rotate(45 16.2632 4.94971)"
                  fill="white"
                />
                <rect
                  x="17.6772"
                  y="16.2637"
                  width="2"
                  height="16"
                  transform="rotate(135 17.6772 16.2637)"
                  fill="white"
                />
              </svg>

              <div className="text-center mt-5 mt-md-6">
                <div className="container narrower">
                  <h2 className="text-orange text-uppercase mb-4">
                    {t("VIPgetGift")}
                  </h2>
                  <p>{t("VIPShareWithUs")}</p>

                  <div className="position-relative">
                    <div className="py-2">
                      <div className="row g-2">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder={t("Name")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            placeholder={t("Surname")}
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            onClick={() => sendEmailVIP()}
                            className="btn gradient-orange text-white px-1 px-5 h-100 text-uppercase fw-bold mt-1"
                            type="button"
                            disabled={loading && "disabled"}>
                            {loading && (
                              <span
                                className="spinner-border spinner-border-md me-3"
                                role="status"
                                aria-hidden="true"></span>
                            )}
                            <span>{t("SendResultForm")}</span>
                          </button>
                        </div>
                        {/*                         <div className="col-12 mt-4  text-start">
                          {isErrorOptin && !optin && (
                            <p className="text-orange small fw-bold mt-3">
                              {t("EmailAgreementRadioValidationError")}
                            </p>
                          )}
                        </div> */}

                        {isSent && (
                          <div className=" d-flex align-items-center text-center justify-content-center">
                            <div>
                              <h2
                                className="text-white fw-bold mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: t("Thankyou"),
                                }}
                              />
                              <motion.img
                                key={isSent}
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                transition={{
                                  duration: 0.5,
                                }}
                                className="sent"
                                src={images["icon-sent.png"]}
                              />
                            </div>
                          </div>
                        )}

                        {isError && (
                          <div className=" d-flex align-items-center text-center justify-content-center">
                            <div>
                              <h3 className="">
                                {t("EmailAgreementRadioError")}
                              </h3>

                              <button
                                onClick={() => setIsError(false)}
                                className="btn btn-orange text-white fw-bold text-uppercase mt-3 px-3"
                                type="button">
                                <span>{t("TryAgain")}</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        {/* <Header trackEvent={trackEvent} images={images} /> */}

        <div className="container narrow result px-3 px-md-4 text-center text-md-start">
          <div className="row g-5">
            <div className="col-md-6">
              <div className="story">
                {data === null && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 2 }}>
                    <p className="mb-2 supertitle">{t("ResultsSupertitle")}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: partialResponse,
                      }}
                    />
                  </motion.div>
                )}

                {data !== null && (
                  <>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 2 }}>
                      <p className="mb-2 supertitle">
                        {t("ResultsSupertitle")}
                      </p>

                      <h1>
                        {listItems[
                          parseInt(data.result.characterIndex) - 1
                        ].replace(/\s*\([^)]*\)\s*/, "")}{" "}
                        from{" "}
                        <ParenthesesMatch
                          title={
                            listItems[parseInt(data.result.characterIndex) - 1]
                          }
                        />
                      </h1>

                      <div
                        className="result-generated"
                        dangerouslySetInnerHTML={{
                          __html: data.result.result,
                        }}
                      />
                    </motion.div>
                    {isDesktopOrLaptop && (
                      <StoryActions
                        t={t}
                        data={data}
                        baseDomain={baseDomain}
                        isVip={isVip}
                        setOpenVip={setOpenVip}
                        openVip={openVip}
                      />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="col-md-6 text-center">
              <motion.img
                className="w-100 rounded-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}
                src="/images/result.jpg"
              />
              {data !== null && (
                <>
                  {!isDesktopOrLaptop && (
                    <StoryActions
                      t={t}
                      data={data}
                      baseDomain={baseDomain}
                      isVip={isVip}
                      setOpenVip={setOpenVip}
                      openVip={openVip}
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <Top5 baseDomain={baseDomain} t={t} />

          <div className="text-center mt-5 block">
            <div className="container narrower">
              <h2 className="text-orange text-uppercase mb-4">
                {t("ResultsBlock1Title")}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("ResultsBlock2Description1"),
                }}
              />
              <p className="fw-bold">{t("ResultsBlock2Description2")}</p>
            </div>
          </div>

          {!isVip && (
            <div className="text-center mt-5 mt-md-6">
              <div className="container narrower">
                <h2 className="text-orange text-uppercase mb-4">
                  {t("NewsletterTitle")}
                </h2>
                <p>{t("NewsletterDescription")}</p>

                <div className="position-relative">
                  <div className="py-2">
                    {isSent && (
                      <div className="message d-flex align-items-center text-center justify-content-center">
                        <div>
                          <p
                            className="text-white fw-bold"
                            dangerouslySetInnerHTML={{
                              __html: t("EmailAgreementRadioSuccess"),
                            }}
                          />
                          <motion.img
                            key={isSent}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            transition={{
                              duration: 0.5,
                            }}
                            className="sent"
                            src={images["icon-sent.png"]}
                          />
                        </div>
                      </div>
                    )}

                    {isError && (
                      <div className="message d-flex align-items-center text-center justify-content-center">
                        <div>
                          <h3 className="">{t("EmailAgreementRadioError")}</h3>

                          <button
                            onClick={() => setIsError(false)}
                            className="btn btn-orange text-white fw-bold text-uppercase mt-3 px-3"
                            type="button">
                            <span>{t("TryAgain")}</span>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="row g-2">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder={t("Email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-4">
                        <button
                          onClick={() => sendEmail()}
                          className="btn gradient-orange text-white px-1 w-100 h-100 text-uppercase fw-bold"
                          type="button"
                          disabled={loading && "disabled"}>
                          {loading && (
                            <span
                              className="spinner-border spinner-border-md me-3"
                              role="status"
                              aria-hidden="true"></span>
                          )}
                          <span>{t("Submit")}</span>
                        </button>
                      </div>
                      <div className="col-12 mt-4  text-start">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="agreement"
                            value={optin}
                            checked={optin === true}
                            onChange={(e) => setOptin(!optin)}
                          />
                          <label
                            className="form-check-label small text-white"
                            htmlFor="agreement"
                            dangerouslySetInnerHTML={{
                              __html: t("EmailAgreementRadioParagraph"),
                            }}
                          />
                        </div>
                        {isErrorOptin && !optin && (
                          <p className="text-orange small fw-bold mt-3">
                            {t("EmailAgreementRadioValidationError")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          badge="inline"
        />
      </motion.div>
    </Hero>
  );
}

export default Result;
