import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import io from "socket.io-client";
import "./i18n/config";
import Home from "views/Home";
import Quiz from "./Quiz";
import Login from "Login";
import Admin from "./Admin";
import Result from "./Result";

import ScrollToTop from "./utils/scrollToTop";
import Footer from "views/Footer";
import Legal from "Legal";
import Cookies from "Cookies";
import VIP from "views/VIP";
import CookieNotice from "views/CookieNotice";
import { getLocalStorage } from "utils/getLocalStorage";
import { useTranslation } from "react-i18next";

function App({ Sentry }) {
  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const [baseDomain, setBaseDomain] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const location = useLocation();

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  var hostname = window.location.hostname;
  var port = window.location.port;

  const URL =
    process.env.NODE_ENV === "production" ? undefined : "http://localhost:4000";
  const socket = io(URL);

  const checkPw = () => {
    // gets the current input value
    const answer = document.getElementById("password").value;

    if (answer === "argeta6712!") {
      setIsVerified(true);
    } else {
      alert("Nope, wrong password.");
    }
  };

  useEffect(() => {
    if (!getLocalStorage("hasAccepted")) {
      setShowCookieNotice(true);
    }

    switch (hostname) {
      case "filmskialterego.argeta.com":
        i18n.changeLanguage("ba");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_BA);
        break;
      case "moviealterego.argeta.com":
        i18n.changeLanguage("en");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_EN);
        break;

      default:
        i18n.changeLanguage("en");
        setIsVerified(true);
        setBaseDomain(process.env.REACT_APP_DOMAIN_BA);
        break;
    }
  }, []);

  if (baseDomain === null) {
    return <div>Loading...</div>;
  }

  // Test

  return (
    <>
      <ScrollToTop />

      {isVerified ? (
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <Home
                  showCookieNotice={showCookieNotice}
                  setShowCookieNotice={setShowCookieNotice}
                  baseDomain={baseDomain}
                  socket={socket}
                />
              }
            />

            <Route
              path="/quiz"
              element={
                <Quiz
                  showCookieNotice={showCookieNotice}
                  setShowCookieNotice={setShowCookieNotice}
                  baseDomain={baseDomain}
                  socket={socket}
                />
              }
            />
            <Route
              path="/result/:userId"
              element={
                <Result type="sender" baseDomain={baseDomain} socket={socket} />
              }
            />

            <Route path="/login" element={<Login baseDomain={baseDomain} />} />
            <Route path="/admin" element={<Admin baseDomain={baseDomain} />} />
            <Route
              path="/legal"
              element={<Legal currentLanguage={currentLanguage} />}
            />
            <Route
              path="/cookies"
              element={<Cookies currentLanguage={currentLanguage} />}
            />

            <Route
              path="/vip"
              element={
                <Home
                  showCookieNotice={showCookieNotice}
                  setShowCookieNotice={setShowCookieNotice}
                  baseDomain={baseDomain}
                  socket={socket}
                  isVip={true}
                />
              }
            />

            <Route
              path="/vip/quiz"
              element={
                <Quiz
                  showCookieNotice={showCookieNotice}
                  setShowCookieNotice={setShowCookieNotice}
                  baseDomain={baseDomain}
                  socket={socket}
                  isVip={true}
                />
              }
            />
            <Route
              path="/vip/result/:userId"
              element={
                <Result
                  type="sender"
                  baseDomain={baseDomain}
                  socket={socket}
                  isVip={true}
                />
              }
            />
          </Routes>
        </AnimatePresence>
      ) : (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <form onSubmit={checkPw}>
            <div className="m-2">
              <label htmlFor="password">Password</label>
              <input type="password" name="password" id="password" />
            </div>
            <div className="m-2">
              <button>Login</button>
            </div>
          </form>
        </div>
      )}
      {showCookieNotice && (
        <CookieNotice
          showCookieNotice={showCookieNotice}
          setShowCookieNotice={setShowCookieNotice}
        />
      )}
      <Footer
        showCookieNotice={showCookieNotice}
        setShowCookieNotice={setShowCookieNotice}
      />
    </>
  );
}

export default App;
