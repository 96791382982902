const Progressbar = ({ currentQuestion }) => {
  return (
    <div className="progress-bar ">
      <div className="d-flex align-items-center bd-highlight">
        <div className="p-2 w-100 bd-highlight">
          {" "}
          <ul className="step-progress-bar ps-0" id="scrollto">
            <li
              className={`stepBullet done `}
              style={{ width: `${(currentQuestion / 12) * 100}%` }}></li>
          </ul>
        </div>
        <div className="p-2 flex-shrink-1 text-white fw-bold">
          {currentQuestion} / 12
        </div>
      </div>
    </div>
  );
};

export default Progressbar;
