import React, { useState, useEffect } from "react";
import { trackEvent } from "views/tracking";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import i18n from "i18n/config";

import AnimationComponent from "views/Common/AnimationComponent";

import { fadeInOut } from "utils/animation.js";

import imgLogoArgeta from "assets/images/_sff/logo-argeta.svg";
import imgLogoSff from "assets/images/_sff/logo-sff.svg";

import imgHeroDesktop from "assets/images/_sff/bg-hero.jpg";
import imgHeroMobile from "assets/images/_sff/bg-hero-mobile.png";

import imgSpacer from "assets/images/_sff/icon-spacer.svg";
import imgArrowRight from "assets/images/_sff/icon-arrow-right.svg";

const Hero = ({ children, t, layout }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 800px)",
  });

  const currentLanguage = i18n.language;

  const changeLocale = () => {
    if (currentLanguage === "en") {
      //i18n.changeLanguage("ba");
      window.location.href = "https://filmskialterego.argeta.com";
    }

    if (currentLanguage === "ba") {
      //i18n.changeLanguage("en");
      window.location.href = "https://moviealterego.argeta.com";
    }
  };

  return (
    <motion.div
      className={`section-hero ${
        layout === "static" && "section-hero-overlay"
      }`}
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit">
      <img
        className="bg-hero d-none"
        src={isDesktopOrLaptop ? imgHeroDesktop : imgHeroMobile}
      />

      <div className="header position-absolute d-flex justify-content-between align-items-center">
        <Link to="/">
          <img src={imgLogoArgeta} className="logo-argeta" />
        </Link>
        <div className="right d-flex align-items-center pe-md-5">
          <a
            href={t("FooterLogo2Link")}
            target="_blank"
            onClick={() => trackEvent("user_interaction", "click", "sff")}>
            <img src={imgLogoSff} className="logo-sff" />
          </a>
          <img src={imgSpacer} className="ms-3 ms-md-4" />
          <button
            onClick={changeLocale}
            className="btn text-white p-3 p-md-4 text-uppercase">
            {currentLanguage === "en" ? "BA" : "EN"}
          </button>
        </div>
      </div>

      {layout !== "static" && (
        <div
          className={`title-animation ${
            layout === "result" && "d-none d-md-block"
          }`}>
          <div className="title-animation-title text-center">
            <h2 className="text-uppercase ">{t("HomeSuperTitle")}</h2>
          </div>
          <div className="title-animation-logos">
            <AnimationComponent layout={layout} />
          </div>
        </div>
      )}

      {children}
    </motion.div>
  );
};
export default Hero;
