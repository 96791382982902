import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { setStory } from "./redux/storySlice";
import ReCAPTCHA from "react-google-recaptcha";
import "./i18n/config";
import { useTranslation } from "react-i18next";

import { trackEvent } from "views/tracking";

import { fadeInOut, containerVariants, child } from "utils/animation.js";
import Progressbar from "views/Quiz/Progressbar.js";
import Loader from "views/Loader";
import Hero from "views/Common/Hero";

import imgSpinner from "assets/images/_sff/spinner.svg";

import { getDataQuestions } from "data/dataQuestions.js";

function Quiz({ baseDomain, isVip }) {
  // Current question ID (we use id from API instead of index)
  const [currentQuestion, setCurrentQuestion] = useState(1);

  // Array of chosen answers
  const [promptVar, setPromptVar] = useState([]);

  // For errors
  const [error, setError] = useState(false);

  // Loader
  const [loading, setLoading] = useState(false);

  const recaptchaRef = useRef(null);
  const scollToRef = useRef();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Translations
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const windowUrl = window.location.search;

  const dataQuestions = getDataQuestions(i18n);

  const handleAnswerOptionClick = (nextQuestion, answerText) => {
    setPromptVar((current) => {
      const uniqueSet = new Set(current); // Create a Set from the current array to remove duplicates
      uniqueSet.add(answerText); // Add the new item to the Set
      return [...uniqueSet]; // Convert the Set back to an array and return it
    });

    trackEvent(
      "user_interaction",
      "completed",
      `question_${currentQuestion}`,
      answerText
    );

    // Check whether next question exists otherwise show results
    if (nextQuestion <= dataQuestions.content.length) {
      setCurrentQuestion(nextQuestion);

      scollToRef.current.scrollIntoView();
    }
  };

  const generateText = async () => {
    const captchaToken =
      localStorage.getItem("captcha") === "success"
        ? "success"
        : await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    try {
      const res = await axios.post(`${baseDomain}/api`, {
        promptVar,
        captchaToken,
      });

      localStorage.setItem("captcha", "success");
      dispatch(setStory(res.data.jobId));

      setLoading(true);

      trackEvent("user_interaction", "select", "answer", promptVar.join(", "));

      setTimeout(() => {
        if (isVip) {
          navigate(`/vip/result/${res.data.jobId}`);
        } else {
          navigate(`/result/${res.data.jobId}`);
        }
      }, 5000);
    } catch (err) {
      console.error("error", err);
      setLoading(false);
      setError(true);
    }
  };

  // Find object of current question
  const filterCurrentQuestion = dataQuestions.content.find(
    (obj) => obj.id === currentQuestion
  );

  // Handle back button

  const handleBackButton = () => {
    setCurrentQuestion(currentQuestion - 1);
    setPromptVar((current) => current.slice(0, -1));
  };

  const handleResetButton = () => {
    setLoading(false);
    setError(false);
    trackEvent("user_interaction", "click", "try_again");
  };

  // Check if we have more than 1 productTags match

  useEffect(() => {
    if (promptVar.length === dataQuestions.content.length) {
      generateText();
      window.scrollTo(0, 0);
    }
  }, [promptVar]);

  return (
    <>
      <Hero t={t} layout={`${!loading && !error ? "quiz" : "home"} `}>
        {loading && (
          <div className="splash">
            <img className="loader" src={imgSpinner} />
            <div
              className="px-4"
              style={{ maxWidth: "500px", margin: "0 auto" }}>
              <p className="pt-5 pt-md-6 small">{t("LoaderSmall")}</p>
            </div>
          </div>
        )}

        {error && (
          <div className="splash px-4">
            <h2 style={{ letterSpacing: 0 }}>{t("LoaderP1")}</h2>

            <h3 className="fw-normal mt-3" style={{ letterSpacing: 0 }}>
              {t("LoaderP2")}
            </h3>

            <button
              onClick={() => handleResetButton()}
              className="btn gradient-orange  text-white fw-bold d-flex align-items-center justify-content-center text-center text-uppercase mx-auto mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="me-2">
                <path
                  d="M13.3333 8H2.66667M2.66667 8L6.66667 12M2.66667 8L6.66667 4"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {t("TryAgain")}
            </button>
          </div>
        )}

        {!loading && !error && (
          <div className="quiz container narrower" ref={scollToRef}>
            <Progressbar currentQuestion={currentQuestion} />

            <AnimatePresence mode="wait">
              <motion.div
                className="h-100"
                key={currentQuestion}
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit">
                <div className="px-2 px-md-0">
                  <motion.p
                    variants={child}
                    className=" lead mb-1 pt-2 pb-3 px-2 text-center ">
                    {filterCurrentQuestion.questionText}
                  </motion.p>

                  <div className={`row g-3`}>
                    {filterCurrentQuestion.answerOptions.map(
                      (answerOption, index) => (
                        <motion.div
                          className={`col-6 col-md-3 d-flex ${
                            answerOption.answerImg
                              ? "image-answer-wrapper"
                              : "text-answer-wrapper"
                          }`}
                          key={index}
                          variants={child}>
                          <button
                            className={`btn btn-orange rounded-4 ${
                              answerOption.answerImg
                                ? "image-answer p-3"
                                : "text-answer py-4"
                            }`}
                            onClick={() => {
                              handleAnswerOptionClick(
                                answerOption.path,
                                answerOption.productTags,
                                answerOption.answerText
                              );
                            }}>
                            {/* Show image if question answerImg true */}
                            {answerOption.answerImg ? (
                              <>
                                <p className="mb-0">
                                  {answerOption.answerText}
                                </p>
                                <div
                                  className={`d-flex align-items-center justify-content-center`}>
                                  <img
                                    className="w-100 m-0 rounded-4"
                                    src={`/images/quiz/${answerOption.answerImg}`}
                                  />
                                </div>
                              </>
                            ) : (
                              <p className="mb-0">{answerOption.answerText}</p>
                            )}
                          </button>
                        </motion.div>
                      )
                    )}
                  </div>
                  {currentQuestion > 1 && (
                    <motion.button
                      variants={child}
                      onClick={handleBackButton}
                      className="btn btn-back text-white w-100 mt-4 d-flex align-items-center justify-content-center mx-auto text-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        className="me-2">
                        <path
                          d="M13.3333 8H2.66667M2.66667 8L6.66667 12M2.66667 8L6.66667 4"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {t("Back")}
                    </motion.button>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
      </Hero>

      <div className="text-center d-inline-block mt-3 ">
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          badge="inline"
        />
      </div>
    </>
  );
}

/* function goToQuiz(location) {
  // Get the section element by ID
  const section = document.getElementById("scrollto");

  // Scroll to the section using scrollIntoView()
  section.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  // Prevent the default link behavior
  return false;
}
 */

/* function goToQuiz() {
  var element = document.getElementById("scrollto");
  var headerOffset = 10;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
} */

export default Quiz;
