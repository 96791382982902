import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { trackEvent } from "./tracking";

import imgArgeta from "assets/images//_sff/logo-argeta-footer.svg";
import imgAtlantic from "assets/images/_lunaai/logo-atlantic.svg";
import imgLogoSff from "assets/images/_sff/logo-sff.svg";

const Footer = ({ showCookieNotice, setShowCookieNotice }) => {
  const { t } = useTranslation();

  return (
    <div className="position-relative footer bg-orange mt-6">
      <div className="content container d-flex align-items-center justify-content-center py-5">
        <div className="row g-0 d-flex align-items-center px-3 ">
          <div className="col-12 mb-5  text-center order-3 order-sm-1">
            <a href={t("FooterLogo1Link")} target="_blank">
              <img
                style={{ width: "100px" }}
                className="mx-2 mx-md-4"
                src={imgArgeta}
              />
            </a>
            <a href={t("FooterLogo2Link")} target="_blank">
              <img
                onClick={() => trackEvent("user_interaction", "click", "sff")}
                className="mx-2 mx-md-4"
                style={{ width: "100px" }}
                src={imgLogoSff}
              />
            </a>
            <a href={t("FooterLogo2Link")} target="_blank">
              <img
                className="mx-2 mx-md-4 mt-5 mt-md-0"
                style={{ width: "100px" }}
                src={imgAtlantic}
              />
            </a>
          </div>
          <div className="col-12  text-center mb-2  order-1 order-sm-2">
            <ul className="list-inline nav-desktop">
              <a
                href="mailto:info@argeta.com"
                target="_blank"
                className="link-white">
                <li className="list-inline-item fw-bold me-md-4">
                  {t("FooterEmail")}
                </li>
              </a>
              <a
                className="link-white"
                style={{ cursor: "pointer" }}
                onClick={() => setShowCookieNotice(true)}>
                <li className="list-inline-item fw-bold me-md-4">
                  {t("FooterCookieSettings")}
                </li>
              </a>

              <Link to="/cookies" className="link-white">
                <li className="list-inline-item fw-bold me-md-4">
                  {t("FooterCookiePolitics")}
                </li>
              </Link>

              <Link to="/legal" className="link-white">
                <li className="list-inline-item fw-bold">
                  {t("FooterPrivacy")}
                </li>
              </Link>
            </ul>
          </div>
          <div className="row order-3 order-sm-3 d-flex justify-content-center">
            <div className="col-12 col-md-auto text-center mb-3 mb-sm-0  text-center">
              <p className="small mb-0">{t("FooterCopyright")}</p>
            </div>
            <div className="col-12 col-md-auto text-center mb-3 mb-sm-0 text-center">
              <p className="small mb-0">{t("Izdelava")}: Escapebox</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
