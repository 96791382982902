import { useEffect } from "react";
import { motion } from "framer-motion";
import { importAll } from "utils/importImages";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import imgSpinner from "assets/images/_sff/spinner.svg";
import Hero from "views/Common/Hero";

// Styles
import "assets/styles/style.scss";

function VIP({ currentLanguage }) {
  const images = importAll(
    require.context("assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("subpage");

    setTimeout(() => {
      setVip();
    }, 2000);

    return () => {
      document.body.classList.remove("subpage");
    };
  }, []);

  const setVip = () => {
    localStorage.setItem("isVip", true);
    navigate("/vip");
  };
  return (
    <Hero t={t} layout="static">
      <motion.div
        className="min-vh-100 d-flex align-items-center justify-content-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        {/* <Header trackEvent={trackEvent} images={images} /> */}
        <div className="splash pt-0">
          <img className="loader" src={imgSpinner} />
          <h3 className="pt-4">{t("VIPPreparing")}</h3>
        </div>
      </motion.div>
    </Hero>
  );
}

export default VIP;
