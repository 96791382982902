function LegalHR() {
  return (
    <div className="hero hero__secondary position-relative  px-2 px-sm-0">
      <div className="container narrow  rounded-5 p-md-5">
        <div>
          <h1>Legal notice</h1>

          <p>
            All content published on the Argeta website is the property of
            ATLANTIC DROGA KOLINSKA d.o.o. and is in the legally permissible
            framework subject to copyright and other forms of intellectual
            property protection.
          </p>

          <p>
            The content (text, photos, logotypes, etc.) published on this
            website may be used exclusively for non-commercial purposes and may
            not be modified, copied, duplicated, republished, sent by mail or
            disseminated in any other way for commercial purposes without the
            express written consent of ATLANTIC DROGA KOLINSKA d.o.o.{" "}
          </p>

          <p>
            All reproductions or copies of the content of this website must also
            include all of the published designations of copyright, other
            notifications of intellectual property rights or notifications of
            other rights (the mark © 2015 ATLANTIC DROGA KOLINSKA d.o.o. All
            rights reserved. ATLANTIC DROGA KOLINSKA d.o.o. is a member of
            Atlantic Grupa).
          </p>

          <p>
            The trademarks and service brands that appear on this website are
            registered trademarks, whose owner or licensee is ATLANTIC DROGA
            KOLINSKA d.o.o. (hereinafter also the Company) or its subsidiaries.
            The use of these trademarks is expressly prohibited except in the
            cases defined herein.
          </p>

          <h2>Privacy Policy</h2>

          <p>
            ATLANTIC DROGA KOLINSKA d.o.o. (hereinafter: the Company) is part of
            the Atlantic Grupa business group, within which high standards are
            established and in use for business management and transparent
            operations, meaning the responsible, lawful and ethically based
            actions of all legal entities of Atlantic Grupa, which at the same
            time includes respect of privacy and the protection of personal
            data.
          </p>

          <p>
            Taking into account the aforementioned standards, the Company as the
            manager of data processing within the Atlantic Grupa business group
            collects and processes personal data lawfully, honestly and
            transparently, in accordance with the valid regulations, and
            implements the appropriate technical and organisational measures to
            protect personal data from unlawful access and abuse.
          </p>

          <p>
            The Company is aware that personal data protection is important both
            to our users and to other individuals. For this very reason we wish
            to inform you which personal data we collect, how we manage it and
            how we ensure its protection.
          </p>

          <h2>Purpose, scope and legal basis for processing</h2>

          <p>
            Personal data are collected for special, express and lawful
            purposes, in the essential scope, and are processed in a manner
            aligned with these purposes.
          </p>

          <p>
            The Company will collect and process personal data only in cases
            where so permitted by regulations:
          </p>

          <p>processing is required in order to fulfil legal obligations;</p>

          <p>
            where the individual assents to the processing of their personal
            data;
          </p>

          <p>
            processing is necessary to implement an agreement in which a party
            to the agreement is an individual to whom the personal data relate,
            or to implement measures at the request of such individual prior to
            the conclusion of an agreement;
          </p>

          <p>
            processing is essential to the protection of the vital interests of
            the individual to whom the data relate or another natural person;
          </p>

          <p>
            processing is required for the lawful interests of the Company or
            associated companies.
          </p>

          <h2>Collection and use of personal data</h2>

          <p>
            Personal data comprise information that directly or indirectly
            identifies you. They include for instance your name and surname,
            e-mail address, telephone number, IP address or postal address.
          </p>

          <p>
            The Company does not collect your personal data except in cases
            where you expressly submit such data (for instance in enquiring
            about certain products or services, concluding an agreement or a
            request for certain actions prior to concluding an agreement,
            through inclusion in loyalty programmes, subscribing to
            e-newsletters, participation in surveys, competitions and prize
            games, ordering samples or brochures, requests for donations and on
            requesting certain information, submitting a CV or open tender or on
            applying for job vacancies, as well as through employment in our
            Company and so forth).
          </p>

          <p>
            Your personal data is collected, stored, used or transmitted by us
            in the extent – in terms of content and time – necessary in the
            given case, for instance to respond to your questions, fulfil your
            requests, notify you of our products or the results of a competition
            or prize game, fulfil contractual obligations or urgently needed
            actions prior to concluding an agreement, or to fulfil our other
            lawful and legal obligations. For these reasons the Company must
            occasionally transmit personal data to other companies in Atlantic
            Grupa and associated companies or external service providers for
            further processing. Service providers may be engaged for instance in
            the context of call centre services, goods distribution or
            advertising material, or for the organisation and implementation of
            prize games. The Company requires of such parties that they adopt
            the valid legal regulations, act in accordance with our instructions
            and that they observe our internal rules on personal data
            protection, as well as take appropriate organisational and technical
            steps for such protection. For these reasons your personal data must
            also be transmitted to other EU Member States or to countries
            outside the EU, but in such transmission the Company undertakes to
            ensure that the transmission is entirely compliant with the law and
            that the transmission will be performed with all available and
            appropriate measures to protect personal data.
          </p>

          <p>
            The Company guarantees that it will not sell your personal data to
            anyone, it will not communicate or lend your personal data to third
            parties if this is not necessary to fulfil a specific purpose for
            which you have given us the personal data, and also guarantees that
            it will not give your personal data to any unauthorised recipient.
          </p>

          <p>
            The possibility exists that we will need to provide your personal
            data to a competent public authority, in the event that the law or
            another binding regulation so requires.
          </p>

          <p>
            Collected data will be stored for as long as this is necessary for
            the purpose for which they were collected, or until the end of the
            period specified in the valid regulations.
          </p>

          <h2>Use of personal data for advertising and marketing purposes</h2>

          <p>
            Where your personal data are used for the purposes of advertising
            and marketing in accordance with the valid regulations governing
            personal data protection, your data will be stored and used for
            these purposes, for instance for sending out e-mail notifications,
            product samples, invitations to participate in competitions and
            prize games by e-mail or mail or some other communication channel
            that you have accepted.
          </p>

          <p>
            If your personal data are to be used for the purpose of sending out
            advertising and promotion tailored to you individually in order to
            create and maintain a user profile, you will be notified in advance
            of this, in other words prior to the sending out of such advertising
            and promotion, and prior to such personal data processing we will of
            course ask specifically and expressly for your properly informed
            consent.
          </p>

          <p>
            We will also use your data if we wish to analyse and improve the
            effectiveness of our online services, advertising, marketing, market
            research and sales efforts in surveys or various other
            questionnaires, or in a manner that ensures that your personal data
            are not stated with your name or in a way that can be linked to a
            specific person.
          </p>

          <h2>Exercising of the rights of the individual</h2>

          <p>
            At any moment you may request from us information about your
            personal data that we process, or request their correction, you may
            cancel their use, or request the deletion of your personal data, or
            revoke consent for all or just certain purposes (except where this
            is not possible, for instance where this involves the processing of
            data for statistical purposes, for which personal data can in no way
            serve for identification), or you can forbid the transmission of
            data to another data processing manager under legal conditions, and
            you may also unsubscribe from receiving our informational and
            promotional materials.
          </p>

          <p>
            You can also lodge an objection to the use of your personal data in
            situations based on the existence of our legitimate interest,
            especially where the data are used for the needs of direct
            marketing.
          </p>

          <p>
            On our part we will work to the greatest possible and reasonable
            extent to ensure that the personal data you have made available to
            us are correctly recorded, and at the same time the responsibility
            for accuracy lies on your side too, so we ask you to regularly and
            punctually update your information, or submit to us a request for
            the entry of changes or corrections.
          </p>

          <p>
            In order to exercise your rights and obligations regarding the use
            of personal data, you can contact us using the e-mail or postal
            address of the Company as stated on the website, or via the e-mail
            address: dpo@atlanticgrupa.com, or by following the instructions on
            delivered promotional material.
          </p>

          <p>
            On being contacted and receiving such requests, we will make
            reasonable efforts to confirm your identity and prevent any
            unauthorised processing of personal data.
          </p>

          <p>
            After receiving your requests we will carry out the requested
            actions in an appropriate timeframe, but no later than in 30 days
            from receipt, and we will as necessary notify you of this, all in
            compliance with the law.
          </p>

          <h2>Children</h2>

          <p>
            As a rule we do not collect data on children. If we determine that
            such data have been communicated to us without the consent of the
            parents or legal representatives, we will delete the data in the
            shortest possible time. Here we will follow the guidelines we
            received from you as parents or from the legal guardian.
          </p>

          <p>
            If nevertheless for certain purposes we might collect data on
            children of an age where under the valid laws the consent of the
            parents is required for the processing of their personal data, we
            will provide appropriate information on this and take reasonable
            steps to determine whether children have the consent of the parents
            or legal guardian.
          </p>

          <h2>Data security</h2>

          <p>
            We have implemented technical and organisational measures to prevent
            the loss of your data, modification, misappropriation or access by
            unauthorised third parties, or any kind of unauthorised processing
            of your data. Access to your personal data is limited to only those
            employees for whom the information is necessary for communicating
            our services or for dealing with your questions. We are constantly
            educating our employees about the importance of data confidentiality
            and maintaining the privacy and security of your data. We collect
            only those data that are necessary for a specific processing
            purpose, and we do not store data longer than is necessary or laid
            down by regulations.
          </p>

          <p>
            On our website you may occasionally find links to other websites or
            portals, and we may also offer the characteristics of social
            networks that enable the sharing of personal data on social
            networks. We recommend in any event that you first read through the
            rules on personal data protection on these portal sites or social
            networks or third persons, as well as on the measures adopted for
            such protection, since they may differ from our rules and our
            adopted measures, for which we cannot be held liable.
          </p>

          <h2>Limitation of liability</h2>

          <p>
            While we are taking available technical, organisational and HR steps
            to protect personal data from random or deliberate misuse,
            destruction, loss, unauthorised modification or access, we cannot
            guarantee that certain personal data we collect, despite the
            provisions of these Rules on the Protection of Privacy, will never
            be randomly disclosed.
          </p>

          <p>
            To the greatest extent allowed by the law, we do not accept
            liability for damage caused to users or third persons by the random
            disclosure of personal data.
          </p>

          <p>
            Since we have no control over the personal data you submit in
            accessing other websites and using them, or that you submit directly
            to third persons (for instance in taking part in sponsored
            activities), to the greatest extent allowed by law we do not accept
            liability for damage that might be incurred by you or third persons
            due to the communication of personal data.
          </p>

          <h2>Updates and amendments</h2>

          <p>
            We reserve the right to amend or update parts of these Rules at any
            moment and without prior notice. Please visit our website from time
            to time so you will be aware of amendments or updates to the Rules.
            The date of entry into force of the latest version of the Rules will
            be posted.
          </p>

          <div class="rules-changed-at">
            These Rules were last amended on 4.2.2020.
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalHR;
