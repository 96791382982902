function CookiesSL() {
  return (
    <div className="hero hero__secondary position-relative  px-2 px-sm-0">
      <div className="container narrow  rounded-5 p-md-5">
        <div class="">
          <h1>Cookies</h1>

          <p>
            Small amounts of information (cookies) are stored on your computer
            to help our website operate properly and to improve your browsing
            experience. Over 90% of websites use cookies, although according to
            European Union rules they are bound to request the consent of the
            user. By using this website you agree to the use of cookies. If you
            do not accept cookies, you can still view the website, though
            certain functions on this website may not be properly displayed or
            implemented.
          </p>

          <h2>What is a cookie?</h2>

          <p>
            A cookie is information stored on your computer by the website that
            you visited. Cookies usually save your settings for the website,
            such as desired language or address. Later, when you once again open
            the same website, the browser sends back cookies that pertain to the
            site. This enables the website to show information adjusted to your
            requirements.
          </p>

          <p>
            Cookies can store a wide range of information, including personal
            information (your name or e-mail). Nevertheless this information can
            only be saved if you enable this – websites cannot gain access to
            information if you have not approved it, nor can they access other
            files on your computer or mobile device. The default settings for
            storing and sending cookies are not visible to you, but you can
            change your browser settings by selecting for yourself whether you
            wish to approve or reject requests for cookies to be saved, whether
            saved cookies are automatically deleted on closing the browser and
            so forth.
          </p>

          <h2>How do you reject cookies?</h2>

          <p>
            By rejecting cookies you are deciding whether you want to allow the
            storage of cookies on your computer or mobile device. The cookie
            settings can be controlled and configured in your browser. If you do
            not accept cookies, it might happen that you will not be able to use
            certain functions on a website.
          </p>

          <h2>What are temporary cookies?</h2>

          <p>
            Temporary or session cookies are deleted from the computer after you
            close the browser. By means of these cookies, websites save
            temporary data such as products in a shopping basket.
          </p>

          <h2>What are persistent cookies?</h2>

          <p>
            Persistent or stored cookies remain on your computer after you close
            the browser programme. By means of these cookies, websites save data
            such as the login name and password, so that you do not need to
            login every time you visit a certain site. Persistent cookies will
            remain on your computer for several days, months or even years.
          </p>

          <h2>What are first party cookies?</h2>

          <p>
            First party cookies come from the website that the user is browsing,
            and can be persistent or temporary. By means of these cookies,
            websites can store data that will be used once again the next time
            the user visits that site.
          </p>

          <h2>What are third-party cookies?</h2>

          <p>
            Third-party cookies originate from other, partner websites (such as
            a pop-up window or other advertising) that are located on the
            website you are visiting. By means of these cookies, websites can
            track your web use for marketing purposes.
          </p>

          <h2>Does this website use cookies?</h2>

          <p>
            Yes, with the primary aim of our website providing you with the best
            user experience.
          </p>

          <h2>What cookies does this website use, and why?</h2>

          <p>
            Temporary or session cookies are cookies that expire (and are
            automatically deleted) when you close the browser. We use temporary
            cookies so that users gain the best experience in using our website.
          </p>

          <p>
            Persistent cookies usually have an expiry date far in the future, so
            they will remain in your browser until they expire, or until you
            delete them manually. We use persistent cookies to better understand
            the habits of users, allowing us to improve the website depending on
            your preferences. The data thus collected cannot be used to identify
            you as a user, and the collected data are used exclusively for
            statistical purposes.
          </p>

          <h2>Are there third-party cookies on this website?</h2>

          <p>
            There are several external services that store limited cookies for
            the user. These cookies have not been placed by this website, but
            some serve to ensure the normal operation of certain functions that
            facilitate user access to content. For this reason information on
            data protection provided to you here applies only to this website,
            and data protection on external service sites needs to be verified
            on their official sites.
          </p>

          <h2>List of cookies</h2>

          <figure class=" table-cookies text-white small">
            <table className="w-100">
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Cookie
                      <br />
                      name
                    </strong>
                  </td>
                  <td>
                    <strong>
                      Storage
                      <br />
                      period
                    </strong>
                  </td>
                  <td>
                    <strong>Provider</strong>
                  </td>
                  <td>
                    <strong>Purpose </strong>
                  </td>
                </tr>
                <tr>
                  <td>__cf_bm</td>
                  <td>30 minutes</td>
                  <td>Cloudflare</td>
                  <td>To read and filter requests from bots.</td>
                </tr>
                <tr>
                  <td>_fbp</td>
                  <td>3 months</td>
                  <td>Facebook</td>
                  <td>To store and track visits across websites.</td>
                </tr>
                <tr>
                  <td>_ga</td>
                  <td>2 years</td>
                  <td>Google Analytics</td>
                  <td>To store and count pageviews.</td>
                </tr>
                <tr>
                  <td>_ga_[container-ID]</td>
                  <td>2 years</td>
                  <td>Google Analytics</td>
                  <td>To store and count pageviews.</td>
                </tr>
                <tr>
                  <td>_gcl_au</td>
                  <td>Persistent</td>
                  <td>Google Adsense</td>
                  <td>To store and track conversions.</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>1 day</td>
                  <td>Google Analytics</td>
                  <td>To distinguish visitors.</td>
                </tr>
                <tr>
                  <td>UID</td>
                  <td>
                    36
                    <br />
                    months
                  </td>
                  <td>iPROM d.o.o.</td>
                  <td>
                    To display personalized ads on
                    <br />
                    other online media after the user leaves this
                    <br />
                    website.
                  </td>
                </tr>
                <tr>
                  <td>language</td>
                  <td>1 year</td>
                  <td>Argeta</td>
                  <td>To store user language settings.</td>
                </tr>
                <tr>
                  <td>Notifications</td>
                  <td>1 year</td>
                  <td>Argeta</td>
                  <td>To follow notifications read by user.</td>
                </tr>
              </tbody>
            </table>
          </figure>

          <h2>Currently we enable:</h2>

          <h3>Measuring visits</h3>

          <p>
            This website uses services for measuring the number of users,
            specifically Google Analytics. The data thus collected cannot be
            used to identify users, and the collected data are used exclusively
            for statistical purposes. If you want to prevent these services from
            storing cookies, you can reject them for each service on the
            following links:
          </p>

          <ul className="text-white">
            <li>
              Google Analytics –{" "}
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
            </li>
          </ul>

          <h2>More information on rejecting cookies</h2>

          <p>
            There are currently several websites for rejecting the storage of
            cookies for various services.
          </p>

          <p>You can find out more on the following links:</p>

          <ul className="text-white">
            <li>
              <a href="http://www.allaboutcookies.org/">
                http://www.allaboutcookies.org/
              </a>
            </li>
            <li>
              <a href="http://www.youronlinechoices.eu/">
                http://www.youronlinechoices.eu/
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CookiesSL;
