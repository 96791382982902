import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { trackEvent } from "views/tracking";
import { motion, AnimatePresence } from "framer-motion";
import { Rating } from "react-simple-star-rating";

import imgArrowRight from "assets/images/_sff/icon-arrow-right.svg";

const StoryActions = ({ baseDomain, t, data, isVip, setOpenVip, openVip }) => {
  const [rating, setRating] = useState(null);

  const onShare = () => {
    const title = data.result.pickedCharacter;
    const url = window.location.href;
    const text = t("SocialP");
    /*     if (navigator.share) {
      navigator
        .share({ title: `${title}`, url: `${url}`, text: `${text}` })
        .then(() => {
          //console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      window.open(
        `https://www.facebook.com/sharer.php?u=${url}&quote=${text}`,
        "_blank"
      );
    } */

    window.open(
      `https://www.facebook.com/sharer.php?u=${url}&quote=${text}`,
      "_blank"
    );

    trackEvent("user_interaction", "click", "share", "");
  };

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    axios
      .post(`${baseDomain}/rate`, {
        jobId: data.jobId,
        rating: rate,
      })
      .then((response) => {
        localStorage.setItem(`rated-${data.jobId}`, true);
        trackEvent("user_interaction", "select", "stars", rate);
      });

    // other logic
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mt-5">
        <Link to="/quiz">
          <button className="btn btn-back px-0 text-white fw-bold d-flex align-items-center justify-content-center text-center text-uppercase ms-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="me-2">
              <path
                d="M13.3333 8H2.66667M2.66667 8L6.66667 12M2.66667 8L6.66667 4"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t("TryAgain")}
          </button>
        </Link>

        {isVip ? (
          <button
            className={`btn btn-orange text-white text-uppercase d-block fw-bold  gradient-orange me-3`}
            onClick={() => setOpenVip(true)}>
            <div className="d-flex align-items-center">
              {t("VIPgetGift")} <img src={imgArrowRight} />
            </div>
          </button>
        ) : (
          <button
            className={`btn btn-orange text-white text-uppercase d-block fw-bold  gradient-orange me-3`}
            onClick={() => onShare()}>
            <div className="d-flex align-items-center">
              {t("Share")} <img src={imgArrowRight} />
            </div>
          </button>
        )}
      </div>
      <div className="mt-5 mt-md-5">
        <h2 className="text-uppercase mb-4">{t("RatingTitle")}</h2>

        <div>
          <div className="row d-flex align-items-center ">
            <div className="col-12 mb-0 position-relative">
              <Rating
                emptyIcon={
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 27 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.9741 1.56533L16.0257 7.69374C16.3669 8.71304 17.3215 9.40012 18.3964 9.40012H24.938C25.426 9.40012 25.6249 10.0275 25.2261 10.3087L20.0117 13.9858C19.1092 14.6222 18.7312 15.7754 19.0817 16.8226L21.0915 22.8262C21.2449 23.2842 20.724 23.6719 20.3293 23.3935L14.9407 19.5937C14.0768 18.9845 12.9232 18.9845 12.0593 19.5937L6.67075 23.3935C6.27599 23.6719 5.75512 23.2842 5.90846 22.8262L7.91826 16.8226C8.26882 15.7754 7.8908 14.6222 6.98832 13.9858L1.77385 10.3087C1.37506 10.0275 1.57404 9.40012 2.062 9.40012H8.60359C9.67848 9.40012 10.6331 8.71304 10.9743 7.69375L13.0259 1.56533C13.1782 1.11029 13.8218 1.1103 13.9741 1.56533Z"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                    />
                  </svg>
                }
                fillIcon={
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 27 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0776 1.24788C12.5346 -0.117217 14.4654 -0.117221 14.9224 1.24788L16.974 7.3763C17.1787 7.98787 17.7515 8.40012 18.3964 8.40012H24.938C26.4019 8.40012 26.9988 10.2823 25.8024 11.126L20.588 14.8031C20.0465 15.1849 19.8197 15.8768 20.03 16.5051L22.0398 22.5087C22.4998 23.8828 20.9372 25.0459 19.753 24.2108L14.3644 20.4109C13.8461 20.0454 13.1539 20.0454 12.6356 20.4109L7.24704 24.2108C6.0628 25.0459 4.50017 23.8829 4.96018 22.5087L6.96999 16.5051C7.18032 15.8768 6.95351 15.1849 6.41202 14.8031L1.19756 11.126C0.00119424 10.2823 0.598094 8.40012 2.062 8.40012H8.60359C9.24853 8.40012 9.82127 7.98787 10.026 7.3763L12.0776 1.24788Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                }
                onClick={handleRating}
                readonly={
                  data !== null &&
                  (localStorage.getItem(`rated-${data.jobId}`) == "true" ||
                    rating !== null)
                }
              />

              {rating !== null && (
                <div style={{ top: "60px" }} className="mt-3">
                  <h3 className="mb-0">{t("RatingThanks")}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StoryActions;
