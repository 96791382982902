import { useState, useReducer, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage } from "utils/getLocalStorage";

const CookieNotice = ({ showCookieNotice, setShowCookieNotice }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [settings, setSettings] = useReducer(
    (prev, next) => {
      const newSettings = { ...prev, ...next };

      return newSettings;
    },
    {
      cookiesAcceptance: true,
      cookiesAnalytics: getLocalStorage("cookies-analytics"),
      cookiesMarketing: getLocalStorage("cookies-marketing"),
    }
  );

  const { t, i18n } = useTranslation();

  const expandNotice = () => {
    setIsExpanded(!isExpanded);
  };

  const clickHandlerCookies = () => {
    setShowCookieNotice(false);
    setLocalStorage("hasAccepted", true);
    if (settings.cookiesAcceptance) {
      setLocalStorage("cookie-acceptance", true);
    }
    if (settings.cookiesAnalytics) {
      setLocalStorage("cookies-analytics", true);
    } else {
      setLocalStorage("cookies-analytics", false);
    }
    if (settings.cookiesMarketing) {
      setLocalStorage("cookies-marketing", true);
    } else {
      setLocalStorage("cookies-marketing", false);
    }

    // If acccept all
    if (!isExpanded) {
      setLocalStorage("cookies-analytics", true);
      setLocalStorage("cookies-marketing", true);
    }

    //window.location.reload();

    window.analytics();
  };

  return (
    <motion.div
      className="cookie-notice"
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "-100%" }}>
      <div className="container cookie-wrapper">
        <div className="row">
          <div className="col-12 col-md-8 d-flex align-items-center">
            <p className="mb-4 mb-md-0">
              {t("CookieNoticeP")}
              {"\u00A0"}
              <Link to={t("CookieNoticePLinkUrl")}>
                {t("CookieNoticePLinkTitle")}
              </Link>
            </p>
          </div>
          <div className="col-12 col-md-4 text-end">
            <div className="text-center">
              <button
                onClick={() => clickHandlerCookies()}
                className="btn btn-white text-orange px-5 px-md-6 py-3 transform-none fw-bold">
                {isExpanded
                  ? t("CookieNoticeButtonAll")
                  : t("CookieNoticeButtonSome")}
              </button>

              <button
                className="btn btn-link text-white  d-flex align-items-center mx-auto"
                onClick={() => expandNotice()}>
                <span className="lh-1 text-uppercase fw-bold">
                  {t("CookiesChangeSettings")}
                </span>
              </button>
            </div>
          </div>
        </div>

        {isExpanded && (
          <motion.div
            className="row mt-5 text-white"
            key={isExpanded}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesAcceptance"
                  checked={settings.cookiesAcceptance}
                  onChange={() =>
                    setSettings({
                      cookiesAnalytics: false,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesAcceptance">
                  <strong>{t("Cookies1H")}</strong>
                  <br />
                  {t("Cookies1P")}
                </label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesAnalytics"
                  checked={settings.cookiesAnalytics}
                  onChange={() =>
                    setSettings({
                      cookiesAnalytics: !settings.cookiesAnalytics,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesAnalytics">
                  <strong>{t("Cookies2H")}</strong>
                  <br />
                  {t("Cookies2P")}
                </label>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="cookiesMarketing"
                  checked={settings.cookiesMarketing}
                  onChange={() =>
                    setSettings({
                      cookiesMarketing: !settings.cookiesMarketing,
                    })
                  }
                />
                <label
                  className="form-check-label small"
                  htmlFor="cookiesMarketing">
                  <strong>{t("Cookies3H")}</strong>
                  <br />
                  {t("Cookies3P")}
                </label>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default CookieNotice;
