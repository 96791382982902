function LegalHR() {
  return (
    <div className="hero hero__secondary position-relative px-2 px-sm-0">
      <div className="container narrow rounded-5 p-md-5">
        <div>
          <h1>Pravno obavještenje</h1>

          <h3>Autorska prava</h3>
          <p>
            Sav sadržaj objavljen na web-stranicama Argeta vlasništvo je društva
            ATLANTIC DROGA KOLINSKA d.o.o. i u zakonski dozvoljenim okvirima
            predmet je autorske zaštite ili drugih oblika zaštite intelektualnog
            vlasništva.
          </p>
          <p>
            Sadržaji (tekstovi, slike, logotipi…) objavljeni na ovim
            web-stranicama mogu se upotrebljavati isključivo u nekomercijalne
            svrhe i ne smiju se mijenjati, prepisivati, umnožavati, ponovo
            objavljivati, slati poštom ili na neki drugi način širiti u
            komercijalne svrhe bez izričite pismene dozvole društva ATLANTIC
            DROGA KOLINSKA d.o.o.
          </p>
          <p>
            Sve reprodukcije ili primjerci sadržaja ovih web-stranica moraju
            sačuvati i sve navedene oznake autorskih prava, drugih obavještenja
            o pravima na intelektualno vlasništvo ili obavještenja o drugim
            pravima (znak © 2015 ATLANTIC DROGA KOLINSKA d.o.o. Sva prava
            zadržana. ATLANTIC DROGA KOLINSKA d.o.o. dio je Atlantic Grupe).
          </p>
          <p>
            Robne i uslužne marke koje se pojavljuju na ovim stranicama
            registrirane su robne marke čiji je vlasnik ili korisnik licence
            ATLANTIC DROGA KOLINSKA d.o.o. (dalje u tekstu i Društvo) ili njena
            povezana društva. Upotreba tih maraka izričito je zabranjena, osim u
            slučajevima koji su utvrđeni ovim tekstom.
          </p>
          <h3>Pravila zaštite privatnosti</h3>
          <p>
            ATLANTIC DROGA KOLINSKA d.o.o. (dalje u tekstu: Društvo) dio je
            poslovnog sistema Atlantic Grupe u okviru kojeg su uspostavljeni i
            promjenjuju se visoki standardi poslovnog upravljanja i
            transparentnog poslovanja, što znači odgovorno, zakonito i etičko
            utemeljeno ponašanje svih pravnih subjekata Atlantic Grupe, koji
            istovremeno uključuju i poštovanje privatnosti i zaštitu ličnih
            podataka.
          </p>
          <p>
            Uz poštovanje spomenutih standarda Društvo, kao kontrolor u okviru
            poslovnog sistema Atlantic Grupe, prikuplja i obrađuje lične podatke
            zakonito, pošteno i transparentno, u skladu s važećim propisima i
            preduzima odgovarajuće tehničke i organizacione mjere za zaštitu
            ličnih podataka od nezakonitog pristupa i zloupotrebe.
          </p>
          <p>
            Društvo je svjesno da je zaštita ličnih podataka važna našim
            korisnicima, kao i drugim licima. Upravo zato vas želimo
            obavijestiti o tome koje lične podatke prikupljamo, kako njima
            upravljamo i kako se brinemo za njihovu zaštitu.
          </p>
          <h3>Svrha, obim i pravni osnov obrade</h3>
          <p>
            Lični podaci prikupljaju se u posebne, izričite i zakonite svrhe, u
            nužnom obimu i obrađuju se na način koji je usklađen s tim svrhama.
          </p>
          <p>
            Društvo će lične podatke prikupljati i obrađivati samo u slučajevima
            kada je to dozvoljeno propisima:
          </p>
          <ul>
            <li>kada je obrada potrebna radi ispunjenja zakonske obaveze;</li>
            <li>
              kada je nosilac podataka dao pristanak za obradu svojih ličnih
              podataka;
            </li>
            <li>
              kada je obrada potrebna za izvršavanje ugovora čija ugovorna
              strana je nosilac podataka ili za primjenu mjera na zahtjev takvog
              pojedinca prije sklapanja ugovora;
            </li>
            <li>
              kada je obrada potrebna za zaštitu životnih interesa nosioca
              podataka ili nekog drugog fizičkog lica;
            </li>
            <li>
              kada je obrada potrebna za zakonite interese Društva ili povezanih
              društava.
            </li>
          </ul>
          <h3>Prikupljanje i upotreba ličnih podataka</h3>
          <p>
            Lični podaci su informacije koje vas direktno ili indirektno
            identifikuju. To su, na primjer, vaše ime i prezime, e-adresa, broj
            telefona, IP adresa ili poštanska adresa.
          </p>
          <p>
            Društvo ne prikuplja vaše lične podatke, osim u slučaju kada vi
            izričito pošaljete takve podatke (na primjer kod slanja upita u vezi
            s određenim proizvodom ili uslugom, sklapanja ugovora ili zahtjeva
            za određene radnje prije sklapanja ugovora, uključivanjem u programe
            vjernosti, prilikom prijave na e-vijesti, učešća u anketama,
            konkursima i nagradnim igrama, naručivanja uzoraka ili brošura,
            slanja molbe za donacije i kod traženja određenih informacija,
            slanja biografije ili otvorene ponude ili prilikom prijave na
            konkurse za slobodna radna mjesta, kao i kod zapošljavanja u našem
            Društvu…).
          </p>
          <p>
            Vaše lične podatke prikupljamo, čuvamo, upotrebljavamo ili prenosimo
            u obimu – sadržajnom i vremenskom – koji je u određenom slučaju
            potreban, na primjer, da bismo odgovorili na vaša pitanja, ispunili
            vaše zahtjeve, obavijestili vas o našim proizvodima ili rezultatima
            konkursa ili nagradne igre, ispunili ugovorne obaveze ili nužne
            radnje prije sklapanja ugovora ili ispunili svoje druge zakonske i
            pravne obaveze. Zato Društvo mora ponekad prenijeti lične podatke na
            daljnju obradu drugim društvima u Atlantic Grupi i povezanim
            društvima ili vanjskim pružaocima usluga. Pružaoci usluga mogu biti
            angažovani, na primjer, u kontekstu pozivnog centra, distribucije
            robe ili reklamnog materijala ili za organizaciju i provođenje
            nagradnih igara. Društvo od tih strana zahtijeva da prihvate važeće
            pravne propise, postupaju u skladu s našim uputstvima i da poštuju
            naša interna pravila o zaštiti ličnih podataka, kao i da prihvate
            odgovarajuće organizacione i tehničke mjere zaštite. Vaši lični
            podaci će zato morati biti preneseni i u druge zemlje EU-a ili
            zemlje izvan EU-a, ali će se Društvo kod takvog prijenosa pobrinuti
            da taj prijenos bude potpuno usklađen sa zakonom i izvršen uz sve
            raspoložive i odgovarajuće mjere zaštite ličnih podataka.
          </p>
          <p>
            Društvo garantuje da vaše lične podatke neće nikome prodavati, neće
            ih prosljeđivati ili posuđivati trećim stranama ako to ne bude
            potrebno za ispunjenje određene svrhe u koju ste nam dali lične
            podatke, a također garantuje da vaše lične podatke neće davati bilo
            kojem neovlaštenom primaocu.
          </p>
          <p>
            Postoji mogućnost da ćemo vaše lične podatke morati dostaviti
            nadležnom organu javne vlasti, u slučaju kada to zahtijeva zakon ili
            neki drugi obavezujući propis.
          </p>
          <p>
            Prikupljeni podaci čuvat će se onoliko koliko je potrebno za svrhu u
            koju su prikupljeni, odnosno do roka utvrđenog važećim propisima.
          </p>
          <h3>Upotreba ličnih podataka u svrhe reklamiranja i prodaje</h3>
          <p>
            Kada se vaši lični podaci upotrebljavaju u svrhe reklamiranja i
            prodaje u skladu s važećim zakonskim propisima iz oblasti zaštite
            ličnih podataka, vaši podaci bit će sačuvani i upotrijebljeni u te
            svrhe, naprimjer za slanje e-poruka, uzoraka proizvoda, poziva na
            učešće u konkursima i u nagradnim igrama e-poštom ili poštom ili
            nekim drugim komunikacionim kanalom koji ste prihvatili.
          </p>
          <p>
            Ako se vaši lični podaci budu upotrebljavali u svrhu slanja
            individualnih (vama) prilagođenih reklama i promotivnih akcija radi
            kreiranja i održavanja korisničkog profila, o tome ćete biti
            unaprijed obaviješteni, dakle još prije slanja takvih reklama i
            promotivnih akcija, a prije takve obrade ličnih podataka zamolit
            ćemo vas za poseban, izričit i informisan pristanak.
          </p>
          <p>
            Vaše podatke također ćemo upotrijebiti ako budemo željeli
            analizirati i poboljšati efikasnost svojih web-usluga, reklamiranja,
            prodaje, tržišnih istraživanja i prodajnih nastojanja u anketama ili
            raznim drugim upitnicima, ili na način koji će obezbjeđivati da se
            vaši lični podaci ne navode imenom, odnosno da se ne mogu povezati s
            konkretnim licem.
          </p>
          <h3>Ostvarivanje prava privatnih lica</h3>
          <p>
            U bilo kojem trenutku od nas možete zahtijevati informaciju o svojim
            ličnim podacima koje obrađujemo ili zahtijevati njihovo
            ispravljanje, opoziv njihove upotrebe ili brisanje ličnih podataka
            ili opoziv pristanka za sve ili samo za neke svrhe (osim kada to
            nije moguće, na primjer, kada je riječ o obradi podataka u
            statističke svrhe za koje lični podaci nikako ne omogućavaju
            identifikaciju), odnosno možete zabraniti prijenos podataka drugom
            kontroloru pod zakonskim uslovima, a također možete otkazati
            primanje naših informativnih i promotivnih materijala.
          </p>
          <p>
            Također možete podnijeti prigovor na upotrebu svojih ličnih podataka
            u situacijama koje se zasnivaju na postojanju našeg legitimnog
            interesa, posebno kada ih upotrebljavamo za potrebe neposredne
            prodaje.
          </p>
          <p>
            Mi ćemo se u najvećoj mogućoj i razumnoj mjeri pobrinuti da lični
            podaci koje ste nam stavili na raspolaganje budu pravilno
            evidentirani, a istovremeno odgovornost za tačnost snosite i vi,
            zato vas molimo da podatke redovno i pravovremeno ažurirate, odnosno
            da nam dostavite zahtjev za upis izmjene ili ispravke.
          </p>
          <p>
            Za ostvarenje svih svojih prava i obaveza u vezi s upotrebom ličnih
            podataka možete se obratiti na e-adresu ili adresu sjedišta Društva
            koje se nalaze na web stranici, ili na e-adresu:
            dpo@atlanticgrupa.com, ili prema uputstvima na dostavljenim
            promotivnim materijalima.
          </p>
          <p>
            Prilikom uspostavljanja kontakta i primanja navedenih zahtjeva
            uložit ćemo razumne napore da potvrdimo vaš identitet i spriječimo
            neovlaštenu obradu ličnih podataka.
          </p>
          <p>
            Nakon što primimo vaše zahtjeve, traženu aktivnost izvršit ćemo u
            odgovarajućem roku, ali najkasnije u roku od 30 dana od primanja te
            ćemo vas o tome po potrebi obavijestiti, a sve u skladu sa zakonom.
          </p>
          <h3>Djeca</h3>
          <p>
            U pravilu ne prikupljamo podatke o djeci. Ako utvrdimo da su nam oni
            poslati bez pristanka roditelja ili zakonskih zastupnika, izbrisat
            ćemo ih u najkraćem mogućem roku. Pritom ćemo poštovati smjernice
            koje smo dobili od vas kao roditelja ili zakonskog staratelja.
          </p>
          <p>
            Ako ipak u određene svrhe prikupimo podatke o djeci koja su stara
            toliko da je u skladu s važećim zakonima za obradu njihovih ličnih
            podataka potreban pristanak roditelja, o tome ćemo objaviti
            odgovarajuću informaciju i upotrijebiti razumne mjere kako bismo
            utvrdili da li djeca imaju pristanak roditelja, odnosno zakonskog
            staratelja.
          </p>
          <h3>Bezbjednost podataka</h3>
          <p>
            Implementirali smo tehničke i organizacione mjere da bismo
            spriječili gubitak vaših podataka, mijenjanje, otuđenje ili pristup
            neovlaštene treće strane, odnosno bilo kakvu njihovu neovlaštenu
            obradu. Pristup vašim ličnim podacima ograničen je samo na one
            zaposlenike kojima su te informacije potrebne za pružanje naših
            usluga, odnosno za rješavanje vašeg pitanja. Svoje zaposlene
            neprestano obrazujemo o tome koliko su važne povjerljivost podataka
            i zaštita privatnosti, kao i zaštita vaših podataka. Prikupljamo
            samo one podatke koji su potrebni za određenu obradu i ne čuvamo ih
            duže nego što je potrebno, odnosno određeno zakonskim propisima.
          </p>
          <p>
            Na našoj web-stranici povremeno ćete moći pronaći veze do drugih
            web-stranica ili portala, a možemo vam ponuditi i funkcije
            društvenih mreža koje omogućavaju dijeljenje ličnih podataka na
            društvenim mrežama. Preporučujemo da svakako prethodno pročitate
            pravila o zaštiti ličnih podataka na tim portalima ili društvenim
            mrežama ili kod trećih lica, kao i o prihvaćenim mjerama za njihovu
            zaštitu, jer se one mogu razlikovati od ovih Pravila i mjera koje
            smo mi prihvatili, za što mi ne možemo biti odgovorni.
          </p>
          <h3>Ograničenje odgovornosti</h3>
          <p>
            Iako primjenjujemo dostupne tehničke, organizacione i kadrovske
            mjere za zaštitu ličnih podataka od slučajne ili namjerne
            zloupotrebe, uništenja, gubitka, neovlaštenih promjena ili pristupa,
            ne možemo garantovati da neki lični podaci koje prikupimo, uprkos
            odredbama ovih Pravila o zaštiti privatnosti, neće nikada biti
            slučajno otkriveni.
          </p>
          <p>
            U najvećoj mjeri dozvoljenoj zakonom isključujemo odgovornost za
            štetu izazvanu korisnicima ili trećim licima slučajnim otkrivanjem
            ličnih podataka.
          </p>
          <p>
            Pošto nemamo kontrolu nad ličnim podacima koje dostavljate prilikom
            pristupa drugim portalima i njihove upotrebe ili direktno trećim
            licima (na primjer prilikom učešća u sponzorisanim aktivnostima), u
            najvećoj mjeri koju dozvoljava zakon isključujemo odgovornost za
            štetu koju biste mogli pretrpiti vi ili treća lica zbog dostavljanja
            ličnih podataka.
          </p>
          <h3>Ažuriranje i izmjene</h3>
          <p>
            Zadržavamo pravo na promjene ili ažuriranje dijelova ovih Pravila u
            svakom trenutku i bez prethodne najave. Povremeno posjetite našu
            web-stranicu da biste se upoznali s promjenama ili ažuriranjem
            Pravila. Bit će objavljen i datum početka važenja aktuelne verzije
            Pravila.
          </p>

          <div class="rules-changed-at">
            Posljednja promjena ovih Pravila izvršena je 4.2.2020.
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalHR;
