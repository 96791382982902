import { createRoot } from "react-dom/client";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);

// Redirect to HTTPS if not already on HTTPS and not on localhost
/* if (
  window.location.protocol !== "https:" &&
  window.location.hostname !== "localhost"
) {
  window.location.href =
    "https:" + window.location.href.substring(window.location.protocol.length);
} */

/* Sentry.init({
  dsn: "https://fe80cf99617c4c20a2123f876fd24914@o1204197.ingest.sentry.io/4505556176011264",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost:3000",
        "https:sff.escapebox.si",
        "https:moviealterego.argeta.com",
        "https:sff.escapebox.si",
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App Sentry={Sentry} />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
