import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import imgAlt1 from "assets/images/_sff/gif/ae1.svg";
import imgAlt2 from "assets/images/_sff/gif/ae2.svg";
import imgAlt3 from "assets/images/_sff/gif/ae3.svg";
import imgAlt4 from "assets/images/_sff/gif/ae4.svg";
import imgAlt5 from "assets/images/_sff/gif/ae5.svg";
import imgAlt6 from "assets/images/_sff/gif/ae6.svg";
import imgAlt7 from "assets/images/_sff/gif/ae7.svg";
import imgAlt8 from "assets/images/_sff/gif/ae8.svg";
import imgAlt9 from "assets/images/_sff/gif/ae9.svg";
import imgAlt10 from "assets/images/_sff/gif/ae10.svg";

const imageUrls = [
  imgAlt1,
  imgAlt2,
  imgAlt3,
  imgAlt4,
  imgAlt5,
  imgAlt6,
  imgAlt7,
  imgAlt8,
  imgAlt9,
  imgAlt10,
];

const AnimationComponent = ({ layout }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (layout === "home") {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
      }
    }, 2500); // Change duration as needed (in milliseconds)

    return () => {
      clearTimeout(timeout);
    };
  }, [currentIndex]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={currentIndex}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}>
        <img src={imageUrls[currentIndex]} alt="SVG Image" />
      </motion.div>
    </AnimatePresence>
  );
};
export default AnimationComponent;
