const getLocalStorage = (item) => {
  if (localStorage.getItem(item) === "true") {
    return true;
  } else {
    return false;
  }
};

const setLocalStorage = (item, set) => {
  return localStorage.setItem(item, set);
};

export { getLocalStorage, setLocalStorage };
