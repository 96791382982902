import { useEffect } from "react";
import { motion } from "framer-motion";
import { importAll } from "utils/importImages";
import { useTranslation } from "react-i18next";
import Hero from "views/Common/Hero";
import Header from "views/Header";

// Styles
import "./assets/styles/style.scss";

import LegalBS from "LegalBS";
import LegalEN from "LegalEN";

function Legal({ currentLanguage }) {
  const images = importAll(
    require.context("./assets/images/_lunaai", false, /\.(png|jpe?g|svg)$/)
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.body.classList.add("subpage");

    return () => {
      document.body.classList.remove("subpage");
    };
  }, []);

  return (
    <Hero t={t} layout="static">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        {/* <Header trackEvent={trackEvent} images={images} /> */}

        <div className="container narrow static px-3 px-md-4 text-center text-md-start">
          {currentLanguage === "ba" && <LegalBS />}
          {currentLanguage === "en" && <LegalEN />}
        </div>
      </motion.div>
    </Hero>
  );
}

export default Legal;
