import { getLocalStorage } from "utils/getLocalStorage";

const trackEvent = async (event, type, context, detail) => {
  const hasAnalytics = localStorage.getItem("cookies-analytics") === "true";

  //GTM
  if (hasAnalytics) {
    try {
      window.dataLayer.push({
        event: event,
        interaction_type: type,
        interaction_context: context,
        interaction_detail: detail,
      });
    } catch (error) {
      // Handle the error gracefully
      console.error("An error occurred while pushing to dataLayer:", error);
    }
  }
  //Plausible
  window.plausible(`${type}_${context}`, {
    props: {
      interaction_detail: detail,
    },
  });
};

export { trackEvent };
