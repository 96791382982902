import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n

  .use(initReactI18next)

  .init({
    fallbackLng: "en",
    detection: {
      order: ["languageDetector", "path"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    resources: {
      sl: {
        translations: require("./locales/sl/translations.json"),
      },
      hr: {
        translations: require("./locales/hr/translations.json"),
      },
      rs: {
        translations: require("./locales/rs/translations.json"),
      },
      en: {
        translations: require("./locales/en/translations.json"),
      },
      ba: {
        translations: require("./locales/ba/translations.json"),
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ["sl", "hr", "rs", "en", "ba"];

export default i18n;
