function CookiesSL() {
  return (
    <div className="hero hero__secondary position-relative  px-2 px-sm-0">
      <div className="container narrow  rounded-5 p-md-5">
        <div class="">
          <h1>Kolačići</h1>

          <p>
            Na vaš računar mora se sačuvati minimalna količina informacija
            (kolačići) da bi web-stranica pravilno radila i da bismo je mogli
            nadograđivati i unapređivati vaše iskustvo tokom pregledanja. Više
            od 90% web-stranica upotrebljava kolačiće, ali prema pravilima
            Evropske unije moraju korisnika zamoliti za pristanak. Upotrebom ove
            web-stranice izražavate svoju saglasnost s upotrebom kolačića, a ako
            ih ne prihvatite, i dalje možete pregledati stranicu, ali se
            određene funkcije na njoj možda neće pravilno prikazati ili
            izvršiti.
          </p>

          <h3>Pravila upotrebe kolačića</h3>
          <p>
            Na vaš računar mora se sačuvati minimalna količina informacija
            (kolačići) da bi web-stranica pravilno radila i da bismo je mogli
            nadograđivati i unapređivati vaše iskustvo tokom pregledanja. Više
            od 90% web-stranica upotrebljava kolačiće, ali prema pravilima
            Evropske unije moraju korisnika zamoliti za pristanak. Upotrebom ove
            web-stranice izražavate svoju saglasnost s upotrebom kolačića, a ako
            ih ne prihvatite, i dalje možete pregledati stranicu, ali se
            određene funkcije na njoj možda neće pravilno prikazati ili
            izvršiti.
          </p>
          <h3>Šta je kolačić?</h3>
          <p>
            Kolačić je informacija koju na vaš računar postavlja web-stranica
            koju ste posjetili. Kolačići obično čuvaju vaše postavke za
            web-stranicu, na primjer željeni jezik ili adresu. Kasnije, kada
            ponovo otvorite istu web-stranicu, pregledač vraća kolačiće koji joj
            pripadaju. To stranici omogućava da prikaže informacije prilagođene
            vašim potrebama.
          </p>
          <p>
            Kolačići mogu čuvati niz informacija, uključujući lične informacije
            (vaše ime ili e-poštu). Uprkos tome ta informacija može se sačuvati
            samo u slučaju da to vi omogućite – web-stranice ne mogu pristupiti
            informacijama ako vi to niste odobrili, a ne mogu ni pristupiti
            drugim datotekama na vašem računaru ili mobilnom uređaju.
            Podrazumijevane postavke čuvanja i slanja kolačića vama nisu
            vidljive, ali možete promijeniti postavke svog pregledača tako da
            sami izaberete da li želite odobriti ili odbiti zahtjeve za čuvanje
            kolačića, automatski izbrisati sačuvane kolačiće prilikom zatvaranja
            pregledača i slično.
          </p>
          <h3>Kako možete odbiti kolačiće?</h3>
          <p>
            Odbijanjem kolačića odlučujete da li želite dozvoliti da se kolačići
            sačuvaju na vašem računaru ili mobilnom uređaju. Postavke kolačića
            mogu se kontrolisati i konfigurisati u vašem pregledaču. Ako
            kolačiće ne prihvatite, može se dogoditi da nećete moći
            upotrebljavati određene funkcije na web-stranici.
          </p>
          <h3>Šta su to privremeni kolačići?</h3>
          <p>
            Privremeni kolačići ili kolačići za sesiju brišu se s računara nakon
            zatvaranja pregledača. Uz njihovu pomoć web-lokacije čuvaju
            privremene podatke, na primjer proizvode u korpi za kupovanje.
          </p>
          <p>
            <strong>Šta su to trajni kolačići?</strong>
          </p>
          <p>
            Trajni ili sačuvani kolačići ostaju na računaru i nakon što
            zatvorite program pregledača. Uz njihovu pomoć web-lokacije čuvaju
            podatke kao što su korisničko ime i lozinka, tako da se ne morate
            prijavljivati prilikom svakog posjećivanja određene web-lokacije.
            Trajni kolačići ostat će na računaru više dana, mjeseci ili čak
            godina.
          </p>
          <p>
            <strong>Šta su to kolačići prve strane?</strong>
          </p>
          <p>
            Kolačići prve strane (vlastiti kolačići) potječu sa web-lokacije
            koju korisnik pregleda i mogu biti trajni ili privremeni. Pomoću tih
            kolačića web-lokacije mogu sačuvati podatke koje će ponovo
            upotrijebiti kada korisnik sljedeći put posjeti tu lokaciju.
          </p>
          <p>
            <strong>Šta su to kolačići treće strane?</strong>
          </p>
          <p>
            Kolačići treće strane (drugi kolačići) potječu sa drugih,
            partnerskih web-lokacija (kao što su skočni prozori ili druge
            reklame), koji se nalaze na web-lokaciji koje pregledate. Pomoću tih
            kolačića web-lokacije mogu pratiti upotrebu interneta u tržišne
            svrhe.
          </p>
          <p>
            <strong>Da li ova stranica upotrebljava kolačiće?</strong>
          </p>
          <p>
            Da, s primarnim ciljem da vam naše web-stranice omoguće najbolje
            korisničko iskustvo.
          </p>
          <p>
            <strong>
              Kakve kolačiće upotrebljava ova web-stranica i zašto?
            </strong>
          </p>
          <p>
            Privremeni kolačići ili kolačići za sesiju (Session cookies) – su
            kolačići koji istječu (i automatski se brišu) kada zatvorite
            pregledač. Mi upotrebljavamo privremene kolačiće kako bi korisnik
            dobio najbolje iskustvo prilikom upotrebe naše web-stranice.
          </p>
          <p>
            Trajni ili sačuvani kolačići (Persistent cookies) njihov datum
            isteka obično je daleko u budućnosti, zato će ostati u vašem
            pregledaču dok ne isteknu ili dok ih ručno ne izbrišete. Mi
            upotrebljavamo trajne kolačiće da bismo bolje shvatili navike
            korisnika, a time možemo poboljšati web-stranicu zavisno od vaših
            navika. Na osnovi tako prikupljenih podataka nije moguća
            identifikacija vas kao korisnika, a prikupljeni podaci
            upotrebljavaju se isključivo u statističke svrhe.
          </p>
          <p>
            <strong>
              Da li na web-stranici postoje kolačići treće strane?
            </strong>
          </p>
          <p>
            Postoji više vanjskih servisa koji za korisnika čuvaju ograničene
            kolačiće. Te kolačiće nije postavila ova web-stranica, ali neki od
            njih služe za normalan rad određenih funkcija koje korisnicima
            olakšavaju pristup sadržaju. Zbog toga informacije o zaštiti
            podataka s kojima se ovdje upoznajete važe samo za ovu web-stranicu,
            a zaštitu podataka na vanjskim servisima treba provjeriti na
            njihovim službenim stranicama.
          </p>
          <p>
            <strong>Trenutno omogućavamo:</strong>
          </p>
          <p>
            <strong>Mjerenje posjeta</strong>
          </p>
          <p>
            Ova web-stranica upotrebljava servise za mjerenje broja korisnika, i
            to Google Analytics. Na osnovi tako prikupljenih podataka nije
            moguća identifikacija korisnika, a prikupljeni podaci upotrebljavaju
            se isključivo u statističke svrhe. Ako želite spriječiti da vam
            navedeni servisi šalju kolačiće, za svaki servis ih možete odbiti na
            sljedećim vezama:
          </p>
          <ul>
            <li>Google Analytics – https://tools.google.com/dlpage/gaoptout</li>
            <li>iProm – UID</li>
          </ul>
          <h3>Dodatne informacije o odbijanju kolačića</h3>
          <p>
            Trenutno postoji više web-stranica za odbijanje čuvanja kolačića za
            različite servise.
          </p>
          <p>Više o tome možete saznati na sljedećim vezama:</p>
          <ul className="text-white">
            <li>http://www.allaboutcookies.org/</li>
            <li>http://www.youronlinechoices.eu/</li>
          </ul>

          <figure class=" table-cookies text-white small">
            <table className="w-100">
              <tbody>
                <tr>
                  <td>
                    <strong>
                      Cookie
                      <br />
                      name
                    </strong>
                  </td>
                  <td>
                    <strong>
                      Storage
                      <br />
                      period
                    </strong>
                  </td>
                  <td>
                    <strong>
                      Who installs
                      <br />
                      the cookie
                      <br />
                      and manages
                      <br />
                      the data it
                      <br />
                      acquires
                    </strong>
                  </td>
                  <td>
                    <strong>Domain</strong>
                  </td>
                  <td>
                    <strong>
                      Purpose of the processing of the data collected
                      <br />
                      by the cookie
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>UID</td>
                  <td>
                    36
                    <br />
                    months
                  </td>
                  <td>iPROM d.o.o.</td>
                  <td>iprom.net</td>
                  <td>
                    iPROM’s advertising cookie allows the system to
                    <br />
                    set a cookie in order to display personalized ads on
                    <br />
                    other online media after the user leaves this
                    <br />
                    website. The system installs the cookie on the user
                    <br />
                    profile (browser) based on user’s prior explicit
                    <br />
                    consent on the website. The user’s profile is
                    <br />
                    anonymous and does not contain personal
                    <br />
                    information.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
    </div>
  );
}

export default CookiesSL;
