import { useRef, useEffect } from "react";

const ImageSlider = ({ images }) => {
  const scrollableRef = useRef(null);
  const animationRef = useRef(null);
  const scrollMultiplier = 0.5; // Adjust this value to control the speed of image movement

  // Easing function for smooth movement with ease-in and ease-out effect
  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  // Event handler for the window scroll event
  const handleScroll = () => {
    if (!scrollableRef.current) {
      return;
    }
    // Get the vertical scroll position of the page
    const scrollY = window.scrollY || window.pageYOffset;

    // Calculate the target scroll position of the div based on the vertical scroll position
    const targetScrollLeft = scrollY * scrollMultiplier;

    // Perform the smooth scroll animation
    const scrollStart = scrollableRef.current.scrollLeft;
    let currentTime = 0;
    const duration = 1000; // Duration of the animation in milliseconds

    const animateScroll = (timestamp) => {
      if (!currentTime) currentTime = timestamp;
      const progress = timestamp - currentTime;
      const scrollValue = easeInOutQuad(Math.min(progress / duration, 1));

      scrollableRef.current.scrollLeft =
        scrollStart + (targetScrollLeft - scrollStart) * scrollValue;

      if (progress < duration) {
        animationRef.current = requestAnimationFrame(animateScroll);
      }
    };

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    animationRef.current = requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and animation frame when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  return (
    <div className="image-slider align-items-center d-flex">
      <div ref={scrollableRef} style={{ overflowX: "hidden" }}>
        <div style={{ display: "flex" }}>
          {images.map((image, index) => (
            <img
              key={index}
              className="image"
              src={`${image}?v2`}
              alt={`Slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
